import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MembersInterface} from '../../../interfaces/telegram/members.interface';
import {ActivatedRoute} from '@angular/router';
import {RestService} from '../../services/rest.service';
import {ChatsInterface} from '../../../interfaces/telegram/chats.interface';
import {IdTranslateService} from '../../services/id-translate.service';
import {AccountInterface} from '../../../interfaces/site/account.interface';
import {SnackbarService} from '../../services/snackbar.service';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-admin-chat',
  templateUrl: './admin-chat.component.html',
  styleUrls: ['./admin-chat.component.scss']
})
export class AdminChatComponent implements OnInit {

  chat: ChatsInterface;
  route: ActivatedRoute

  tinyMceSettings = {
    inline: false,
    statusbar: true,
    browser_spellcheck: true,
    height: 320,
    toolbar: 'formatselect | bold italic underline | bullist numlist | undo redo ',
    plugins: 'link',
  };
  navigation: string = "settings";

  constructor(
    route: ActivatedRoute,
    private rest: RestService,
    private snackbar: SnackbarService,
    public auth: AuthService
  ) {
    this.route = route;
    this.route.params.subscribe(params => {
      this.reload(params['id']);
    })
  }

  ngOnInit(): void {
    this.chat = null;
  }

  reload(id: string) {
    this.rest.get('chats/' + id)
      .subscribe(
        (res) => {
          console.log(res);
          this.chat = res;
        },
        (err) => {
          console.log(err);
        }
      )
  }

  initSettings() {
    //todo: make sure user has perms for this.
    this.rest.post('chat-settings/',
      {
        useCoc: true,
        useWelcome: false,
        useAntiflood: false,
        useSpecificAntifloodMediaFilter: false,
        useGatekeeper: false,
        useForbidden: false,
        chatId: this.chat.id,
        antifloodLimit: 5,
        antifloodRate: 10
      } as any)
      .subscribe(
        (res) => {
          this.reload(this.chat.id);
          this.snackbar.open("Initialized Settings. Run /reload in the group to apply.")
        },
        (err) => {
          console.log(err);
          this.snackbar.open("Failed to Initialize Settings. Check Console.")
        }
      )

  }

  saveItem(key: string, value: any) {
    if (this.chat.settings) {
      let fragment = {
        useCoc: this.chat.settings.useCoc,
        useWelcome: this.chat.settings.useWelcome,
        useAntiflood: this.chat.settings.useAntiflood,
        useSpecificAntifloodMediaFilter: this.chat.settings.useSpecificAntifloodMediaFilter,
        useGatekeeper: this.chat.settings.useGatekeeper,
        useForbidden: this.chat.settings.useForbidden
      }
      fragment[key] = value;

      this.rest.patch('chat-settings/' + this.chat.settings.id+'/', fragment)
        .subscribe(
          (res) => {
            this.snackbar.open("Saved setting '" + key + "' successfully.");
          },
          (err) => {
            console.log(err);
          }
        )
    }
  }

  saveItemNumber(key: string, value: number) {
    this.saveItem(key, value as number);
  }

  saveItemChat(key: string, value: any) {
    let fragment = {
      id: this.chat.id,
      name: this.chat.name,
    }
    fragment[key] = value;
    if (this.auth.authorizedUser.account.is_staff) {
      this.rest.patch('chats/' + this.chat.id + '/', fragment)
        .subscribe(
          (res) => {
            this.snackbar.open("Saved Changes.")
          },
          (err) => {
            console.log(err);
          }
        )

    }
  }
}
