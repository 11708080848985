  <div class="row" style="margin-left: 0px; margin-right: 0px;">
  <div class="col-md-4" style="background-color: #333; padding-left: 25px; margin-top: 5px;">
    <h1 style="margin-top: 0px;" class="hf-title">HypnoFurs <small style="font-size: 20px;"><em>just relax...</em></small></h1>
  </div>
  <header class="header-1 col-md-8">
    <div class="header-nav">
      <a routerLink="/" class="nav-link nav-text">Home</a>
      <a href="javascript://" class="nav-link nav-text">Files</a>
      <a routerLink="/chats" class="nav-link nav-text">Chats</a>
      <a routerLink="/admin/dashboard" class="nav-link nav-text" *ngIf="authService.authorizedUserAccount?.isMod || authService.authorizedUserAccount?.isAdmin || authService.authorizedUserAccount?.is_staff">Moderation</a>
    </div>
    <div class="header-actions">
      <!-- <a href="javascript://" (click)="snackbar.open('be gey')" class="nav-link nav-text">Code of Conduct</a> -->
      <a class="nav-link nav-text" routerLink="/coc">Code of Conduct</a>
      <a class="nav-link nav-text" routerLink="/system/dashboard" *ngIf="authService.authorizedUserAccount?.is_staff">System</a>
      <a class="nav-link nav-text" routerLink="/login" *ngIf="authService.authorizedUser === null">Login</a>
      <a class="nav-link nav-text" routerLink="/my" *ngIf="authService.authorizedUser != null">{{authService.authorizedUser.name}}</a>
    </div>
  </header>
  </div>
  <router-outlet></router-outlet>

  <footer class="container">
    <hr style="background: gray;">
    <div class="row">
      <div class="col-md-5">
        <p style="color: gray">
          Made with &hearts; by Yuukari<br>
          &copy; 2021 HypnoFurs.org<br>
          You read to the bottom. I'm proud of you &lt;3 <br>
          (App: v0.1.10 / API: v{{this.ping.version}})
        </p>
      </div>
      <div class="col-md-7">
        <p class="text-right">
          <a routerLink="/tos">Terms of Service</a><br>
          <a routerLink="/aup">Acceptable Upload Policy</a><br>
          <a routerLink="/privacy">Privacy Policy</a><br>
          <a href="#" *ngIf="authService.isLoggedIn()" (click)="authService.logout()">Logout</a><br>
          Return to Top
        </p>
      </div>
    </div>
  </footer>
<snack-bar-container></snack-bar-container>
