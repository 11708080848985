<div class="hf-first-content-container container" *ngIf="auth.authorizedUserAccount === null">
  <h1>Please wait while we check your credentials...</h1>
</div>
<div class="main-container">
  <div class="content-container" *ngIf="auth.authorizedUserAccount != null">
    <clr-vertical-nav [clrVerticalNavCollapsible]="true" [(clrVerticalNavCollapsed)]="collapsed">
      <a clrVerticalNavLink routerLink="/system/dashboard" routerLinkActive="active">
        <cds-icon clrVerticalNavIcon shape="dashboard"></cds-icon>
        Dashboard
      </a>
      <!--
      <a clrVerticalNavLink routerLink="/system/database" routerLinkActive="active">
        <cds-icon clrVerticalNavIcon shape="data-cluster"></cds-icon>
        Database
      </a>
      -->
      <a clrVerticalNavLink routerLink="/system/users" routerLinkActive="active">
        <cds-icon clrVerticalNavIcon shape="users"></cds-icon>
        Users
      </a>
      <a clrVerticalNavLink routerLink="/system/announcements" routerLinkActive="active">
        <cds-icon clrVerticalNavIcon shape="bubble-exclamation"></cds-icon>
        Announcements
      </a>
    </clr-vertical-nav>
    <div class="content-area">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
