<h3 class="container text-white">{{chat.name}} <small>{{chat.id}}</small></h3>
<div class="hf-content-container container" *ngIf="auth.isUserAuthentic">
  <div class="row">
    <div class="col-md-4">
      <h5>Staff</h5>
      <div *ngFor="let staff of chat.staff">
        <span>@{{staff.member.handle}}</span>  <em style="color: darkgray; font-size: 11px;">({{staff.rank}})</em>
      </div>
    </div>
    <div class="col-md-8">

      <nav class="subnav" style="background: #333">
        <ul class="nav">
          <li class="nav-item">
            <a class="nav-link" (click)="navigation = 'settings'" href="javascript://">Settings</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="navigation = 'warns'" href="javascript://">Warns</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="navigation = 'bans'" href="javascript://">Bans</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="navigation = 'activity'" href="javascript://">Activity</a>
          </li>
          <li class="nav-item" *ngIf="auth.authorizedUser.account.is_staff" style="background: white !important;">
            <a class="nav-link" (click)="navigation = 'site-settings'" href="javascript://">Site Settings</a>
          </li>
        </ul>
      </nav>

      <div *ngIf="navigation === 'site-settings'" class="bg-light" style="border-radius: 10px; padding: 20px">
        <h2>Site Settings <small style="font-size: 16px; color: darkgray">(Super Admin Settings)</small></h2>
        <div class="card">
          <div class="card-header">
            Site Visibility
          </div>
          <div class="card-block">
            <div class="card-title">
              Hide from Public Lists?
            </div>
            <div class="card-text">
              <input clrCheckbox type="checkbox" (change)="saveItemChat('hidePublic', chat.hidePublic)" [(ngModel)]="chat.hidePublic">
              <span class="help-text">Hide this chat from public lists?</span>
            </div>
            <div class="card-title">
              Is this a staff channel?
            </div>
            <div class="card-text">
              <input clrCheckbox type="checkbox" (change)="saveItemChat('isStaffChannel', chat.isStaffChannel)" [(ngModel)]="chat.isStaffChannel">
              <span class="help-text">Is this channel for staff?</span>
            </div>
            <div class="card-title">
              Authorized to use Bot?
            </div>
            <div class="card-text">
              <input clrCheckbox type="checkbox" (change)="saveItemChat('authorized', chat.authorized)" [(ngModel)]="chat.authorized">
              <span class="help-text">Is this chat authorized to use this bot?</span>
            </div>
            <div class="card-title">
              Part of HF Network?
            </div>
            <div class="card-text">
              <input clrCheckbox type="checkbox" (change)="saveItemChat('networkMember', chat.networkMember)" [(ngModel)]="chat.networkMember">
              <span class="help-text">Is this chat part of the HF Network?</span>
            </div>
            <div class="card-title">
              Category
            </div>
            <div class="card-text">
              <input clrInput (change)="saveItemChat('category', chat.category)" [(ngModel)]="chat.category">
              <span class="help-text">Select the category for this chat</span>
            </div>
            <div class="card-title">
              Join Link
            </div>
            <div class="card-text">
              <input clrInput (change)="saveItemChat('joinLink', chat.joinLink)" [(ngModel)]="chat.joinLink">
              <span class="help-text">Paste the join link above.</span>
            </div>
            <div class="card-title">
              Join Prohibited?
            </div>
            <div class="card-text">
              <input clrCheckbox type="checkbox" (change)="saveItemChat('joinProhibit', chat.joinProhibit)" [(ngModel)]="chat.joinProhibit">
              <span class="help-text">If true, will display a message instead of a join button telling the user to contact an admin to join.</span>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="navigation === 'settings'">
        <div *ngIf="chat.settings">
          <h3 class="text-light">Settings</h3>
          <div class="card">
            <div class="card-header">
              Chat Rules
            </div>
            <div class="card-block">
              <div class="card-title">
                Use CoC?
              </div>
              <div class="card-text">
                <input clrCheckbox type="checkbox" (change)="saveItem('useCoc', chat.settings.useCoc)" [(ngModel)]="chat.settings.useCoc">
                <span class="help-text">Use CoC as your group's rule basis. (For HF Network Chats, this is mandatory.)</span>
              </div>
              <div class="card-title">
                Rules
              </div>
              <div class="card-text">
                <editor apiKey="rgp815ufesp5cf8sgr4dkgamv3zokad3gruha1m66nrp968w" name="body" [init]="tinyMceSettings" [(ngModel)]="chat.settings.rules"></editor>
                <button (click)="saveItem('rules', chat.settings.rules)" class="btn btn-success btn-block">Save</button>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              Site Settings
            </div>
            <div class="card-block">
              <div class="card-title">
                Group Welcome Message
              </div>
              <div class="card-text">
                <div class="help-text">This text is displayed in the chat listing on the website.</div>
                <editor apiKey="rgp815ufesp5cf8sgr4dkgamv3zokad3gruha1m66nrp968w" name="body2" [init]="tinyMceSettings" [(ngModel)]="chat.settings.groupMessage"></editor>
                <button (click)="saveItem('groupMessage', chat.settings.groupMessage)" class="btn btn-success btn-block">Save</button>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              Chat Welcome Settings
            </div>
            <div class="card-block">
              <div class="card-title">
                Show Welcome Message to New Users?
              </div>
              <div class="card-text">
                <input clrCheckbox type="checkbox" (change)="saveItem('useWelcome', chat.settings.useWelcome)" [(ngModel)]="chat.settings.useWelcome">
                <span class="help-text">When checked, the text below will be shown to new users who enter the chat.</span>
              </div>
              <div *ngIf="chat.settings.useWelcome">
              <div class="card-title">
                Welcome Message Text
              </div>
              <div class="card-text">
                <div class="help-text">This text is displayed in the welcome message, if enabled..</div>
                <editor apiKey="rgp815ufesp5cf8sgr4dkgamv3zokad3gruha1m66nrp968w" name="body3" [init]="tinyMceSettings" [(ngModel)]="chat.settings.welcome"></editor>
                <button (click)="saveItem('welcome', chat.settings.welcome)" class="btn btn-success btn-block">Save</button>
              </div>
              <div class="card-title">
                Show Rules Link in Welcome Message?
              </div>
              <div class="card-text">
                <input clrCheckbox type="checkbox" (change)="saveItem('welcomeShowRulesLink', chat.settings.welcomeShowRulesLink)" [(ngModel)]="chat.settings.welcomeShowRulesLink">
                <span class="help-text">When checked, a link to the chat rules will be appended to the welcome message above.</span>
              </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              Antiflood Settings
            </div>
            <div class="card-block">
              <div class="card-title">
                Use Antiflood?
              </div>
              <div class="card-text">
                <input clrCheckbox type="checkbox" (change)="saveItem('useAntiflood', chat.settings.useAntiflood)" [(ngModel)]="chat.settings.useAntiflood">
                <span class="help-text">When checked, The antiflood paramaters set below will be live after reload.</span>
              </div>
              <div *ngIf="chat.settings.useAntiflood">
              <div class="card-title">
                Antiflood Action
              </div>
              <div class="card-text">
                <input clrCheckbox type="checkbox" (change)="saveItem('antifloodAction', chat.settings.antifloodAction)" [(ngModel)]="chat.settings.antifloodAction">
                <span *ngIf="chat.settings.antifloodAction" class="text-danger">User will be Locally Banned if Antiflood is Triggered.</span>
                <span *ngIf="!chat.settings.antifloodAction" class="text-warning">User will be Kicked if Antiflood is Triggered.</span><br>
                <span class="help-text">When checked, The antiflood paramaters set below will be live after reload.</span>
              </div>
              <div class="card-title">
                Antiflood Rate
              </div>
              <div class="card-text">
                <input clrInput (change)="saveItem('antifloodRate', chat.settings.antifloodRate)" [(ngModel)]="chat.settings.antifloodRate">
                <span class="help-text">Sets the length of time (in seconds) that the number of antiflood posts needs to exceed in order to trigger antiflood</span>
              </div>
              <div class="card-title">
                Antiflood Limit
              </div>
              <div class="card-text">
                <input clrInput (change)="saveItem('antifloodLimit', chat.settings.antifloodLimit)" [(ngModel)]="chat.settings.antifloodLimit">
                <span class="help-text">Sets the number of posts needed in order to trigger antiflood in the set time period.</span>
              </div>
              </div>
            </div>
          </div>
          <div class="card" *ngIf="chat.settings.useAntiflood">
            <div class="card-header">
              Antiflood Media Type Filtering
            </div>
            <div class="card-block">
              <div class="card-title">
                Filter Specific Media Types?
              </div>
              <div class="card-text">
                <input clrCheckbox type="checkbox" (change)="saveItem('useSpecificAntifloodMediaFilter', chat.settings.useSpecificAntifloodMediaFilter)" [(ngModel)]="chat.settings.useSpecificAntifloodMediaFilter">
                <span class="help-text">When checked, you will be allowed to select specific message types that will be affected by the antiflood filter.</span>
              </div>
              <div *ngIf="chat.settings.useSpecificAntifloodMediaFilter">
              <div class="card-title">
                Filter Text?
              </div>
              <div class="card-text">
                <input clrCheckbox type="checkbox" (change)="saveItem('antifloodText', chat.settings.antifloodText)" [(ngModel)]="chat.settings.antifloodText">
                <span class="help-text">Should text be included in antiflood?</span>
              </div>
              <div class="card-title">
                Filter Images?
              </div>
              <div class="card-text">
                <input clrCheckbox type="checkbox" (change)="saveItem('antifloodImages', chat.settings.antifloodImages)" [(ngModel)]="chat.settings.antifloodImages">
                <span class="help-text">Should Images be included in antiflood?</span>
              </div>
              <div class="card-title">
                Filter Videos?
              </div>
              <div class="card-text">
                <input clrCheckbox type="checkbox" (change)="saveItem('antifloodVideos', chat.settings.antifloodVideos)" [(ngModel)]="chat.settings.antifloodVideos">
                <span class="help-text">Should videos be included in antiflood?</span>
              </div>
              <div class="card-title">
                Filter Forwarded Messages?
              </div>
              <div class="card-text">
                <input clrCheckbox type="checkbox" (change)="saveItem('antifloodForwardedMessages', chat.settings.antifloodForwardedMessages)" [(ngModel)]="chat.settings.antifloodForwardedMessages">
                <span class="help-text">Should Forwarded Messages be included in antiflood?</span>
              </div>
              <div class="card-title">
                Filter Stickers?
              </div>
              <div class="card-text">
                <input clrCheckbox type="checkbox" (change)="saveItem('antifloodStickers', chat.settings.antifloodStickers)" [(ngModel)]="chat.settings.antifloodStickers">
                <span class="help-text">Should stickers be included in antiflood?</span>
              </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              Gatekeeper
            </div>
            <div class="card-block">
              <div class="card-title">
                Use Gatekeeper?
              </div>
              <div class="card-text">
                <input clrCheckbox type="checkbox" (change)="saveItem('useGatekeeper', chat.settings.useGatekeeper)" [(ngModel)]="chat.settings.useGatekeeper">
                <span class="help-text">Use Gatekeeper Captcha Functionality (Not enabled at this time)</span>
              </div>
              <div *ngIf="chat.settings.useGatekeeper">
              <div class="card-title">
                Gatekeeper Enforcement Action
              </div>
              <div class="card-text">
                <input clrCheckbox type="checkbox" (change)="saveItem('gatekeeperEnforcmentAction', chat.settings.gatekeeperEnforcmentAction)" [(ngModel)]="chat.settings.gatekeeperEnforcmentAction">
                <span *ngIf="chat.settings.gatekeeperEnforcmentAction" class="text-danger">User will be Locally Banned if Gatekeeper is Triggered.</span>
                <span *ngIf="!chat.settings.gatekeeperEnforcmentAction" class="text-warning">User will be Kicked if Gatekeeper is Triggered.</span><br>
                <span class="help-text">Gatekeeper Enforcement Action</span>
              </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              Forbidden Media
            </div>
            <div class="card-block">
              <div class="card-title">
                Restrict Media?
              </div>
              <div class="card-text">
                <input clrCheckbox type="checkbox" (change)="saveItem('useForbidden', chat.settings.useForbidden)" [(ngModel)]="chat.settings.useForbidden">
                <span class="help-text">Set some media to be restricted in your chatroom and result in a media warning for the offending user.</span>
              </div>
              <div *ngIf="chat.settings.useForbidden">
                <div class="card-title">
                  Forbid Voice?
                </div>
                <div class="card-text">
                  <input clrCheckbox type="checkbox" (change)="saveItem('forbidVoice', chat.settings.forbidVoice)" [(ngModel)]="chat.settings.forbidVoice">
                  <span class="help-text">Forbid voice in the chat?</span>
                </div>
                <div class="card-title">
                  Forbid Gifs?
                </div>
                <div class="card-text">
                  <input clrCheckbox type="checkbox" (change)="saveItem('forbidGifs', chat.settings.forbidGifs)" [(ngModel)]="chat.settings.forbidGifs">
                  <span class="help-text">Forbid gifs in the chat?</span>
                </div>
                <div class="card-title">
                  Forbid Documents?
                </div>
                <div class="card-text">
                  <input clrCheckbox type="checkbox" (change)="saveItem('forbidDocuments', chat.settings.forbidDocuments)" [(ngModel)]="chat.settings.forbidDocuments">
                  <span class="help-text">Forbid documents in the chat?</span>
                </div>
                <div class="card-title">
                  Forbid Games?
                </div>
                <div class="card-text">
                  <input clrCheckbox type="checkbox" (change)="saveItem('forbidGames', chat.settings.forbidGames)" [(ngModel)]="chat.settings.forbidGames">
                  <span class="help-text">Forbid games in the chat?</span>
                </div>
                <div class="card-title">
                  Forbid Contacts?
                </div>
                <div class="card-text">
                  <input clrCheckbox type="checkbox" (change)="saveItem('forbidContacts', chat.settings.forbidContacts)" [(ngModel)]="chat.settings.forbidContacts">
                  <span class="help-text">Forbid contacts in the chat?</span>
                </div>
                <div class="card-title">
                  Forbid Images?
                </div>
                <div class="card-text">
                  <input clrCheckbox type="checkbox" (change)="saveItem('forbidImages', chat.settings.forbidImages)" [(ngModel)]="chat.settings.forbidImages">
                  <span class="help-text">Forbid images in the chat?</span>
                </div>
                <div class="card-title">
                  Forbid Videos?
                </div>
                <div class="card-text">
                  <input clrCheckbox type="checkbox" (change)="saveItem('forbidVideos', chat.settings.forbidVideos)" [(ngModel)]="chat.settings.forbidVideos">
                  <span class="help-text">Forbid videos in the chat?</span>
                </div>
                <div class="card-title">
                  Forbid Files?
                </div>
                <div class="card-text">
                  <input clrCheckbox type="checkbox" (change)="saveItem('forbidFiles', chat.settings.forbidFiles)" [(ngModel)]="chat.settings.forbidFiles">
                  <span class="help-text">Forbid files in the chat?</span>
                </div>
                <div class="card-title">
                  Forbid Stickers?
                </div>
                <div class="card-text">
                  <input clrCheckbox type="checkbox" (change)="saveItem('forbidStickers', chat.settings.forbidStickers)" [(ngModel)]="chat.settings.forbidStickers">
                  <span class="help-text">Forbid stickers in the chat?</span>
                </div>
                <div class="card-title">
                  Forbid Location?
                </div>
                <div class="card-text">
                  <input clrCheckbox type="checkbox" (change)="saveItem('forbidLocation', chat.settings.forbidLocation)" [(ngModel)]="chat.settings.forbidLocation">
                  <span class="help-text">Forbid location in the chat?</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!chat.settings">
          <h2 class="text-light">Settings is not initialized yet.</h2>
          <button class="btn btn-success" (click)="initSettings()">Initialize Settings for this Group</button>
        </div>
      </div>
    </div>
  </div>
</div>
