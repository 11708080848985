import { Component, OnInit } from '@angular/core';
import {AuthService} from '../services/auth.service';
import {RestService} from '../services/rest.service';

@Component({
  selector: 'app-agreements',
  templateUrl: './agreements.component.html',
  styleUrls: ['./agreements.component.scss']
})
export class AgreementsComponent implements OnInit {

  constructor(
    public auth: AuthService,
    public rest: RestService
  ) { }

  ngOnInit(): void {
  }

  acceptTos() {
    let fragment = {
      acceptTos: true
    }
    this.rest.patch('accounts/'+this.auth.authorizedUserAccount.id+'/', fragment)
      .subscribe(
        (res) => {
          this.auth.authorizedUserAccount.acceptTos = true;
        },
        (err) => {
          console.log(err);
        }
      )
  }

  acceptAup() {
    let fragment = {
      acceptAup: true
    }
    this.rest.patch('accounts/'+this.auth.authorizedUserAccount.id+'/', fragment)
      .subscribe(
        (res) => {
          this.auth.authorizedUserAccount.acceptAup = true;
        },
        (err) => {
          console.log(err);
        }
      )
  }

  acceptPriv() {
    let fragment = {
      acceptPriv: true
    }
    this.rest.patch('accounts/'+this.auth.authorizedUserAccount.id+'/', fragment)
      .subscribe(
        (res) => {
          this.auth.authorizedUserAccount.acceptPriv = true;
        },
        (err) => {
          console.log(err);
        }
      )
  }
}
