import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemLayoutComponent } from './system-layout/system-layout.component';
import { SystemDashboardComponent } from './system-dashboard/system-dashboard.component';
import {SystemRoutingModule} from './system-routing.module';
import {ClarityModule} from '@clr/angular';
import { SystemImportExportComponent } from './system-import-export/system-import-export.component';
import { SystemUsersComponent } from './system-users/system-users.component';
import { SystemUsersDetailComponent } from './system-users/system-users-detail/system-users-detail.component';
import { SystemAnnouncementsComponent } from './system-announcements/system-announcements.component';
import {FormsModule} from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';



@NgModule({
  declarations: [
    SystemLayoutComponent,
    SystemDashboardComponent,
    SystemImportExportComponent,
    SystemUsersComponent,
    SystemUsersDetailComponent,
    SystemAnnouncementsComponent
  ],
  imports: [
    CommonModule,
    SystemRoutingModule,
    ClarityModule,
    FormsModule,
    EditorModule
  ]
})
export class SystemModule { }
