import { Component, OnInit } from '@angular/core';
import {RestService} from '../services/rest.service';
import {ChatsInterface} from '../../interfaces/telegram/chats.interface';

@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss']
})
export class ChatListComponent implements OnInit {

  chats: ChatsInterface[] = [];

  constructor(
    private rest: RestService
  ) { }

  ngOnInit(): void {
    this.rest.get('public/chats/')
      .subscribe(
        (res) => {
          this.chats = res;
        },
        (err) => {
          console.log(err);
        }
      )
  }

  openLink(joinLink: string) {
    // captcha
  }
}
