<div class="hf-first-content-container container">
  <h1>Announcements</h1>
  <hr>
  <h4>New Announcement</h4>
  <form clrForm clrLayout="horizontal">
    <clr-input-container>
      <label>Title</label>
      <input clrInput placeholder="Title Here" name="title" [(ngModel)]="this.dataModel.title" />
      <clr-control-error>This field cannot be empty!</clr-control-error>
    </clr-input-container>
          <editor apiKey="rgp815ufesp5cf8sgr4dkgamv3zokad3gruha1m66nrp968w" name="body" [init]="tinyMceSettings" [(ngModel)]="this.dataModel.body"></editor>
<br>
    <clr-checkbox-wrapper>
      <label>Post to Channels?</label>
      <input clrCheckbox type="checkbox" required value="option1" name="postToChannels" [(ngModel)]="this.dataModel.postToChannels" />
      <clr-control-error>This field cannot be empty!</clr-control-error>
    </clr-checkbox-wrapper>

    <button class="btn btn-success btn-block" (click)="doSubmit()">Post</button>
  </form>
  <hr>
  <h4>Posted Announcements</h4>
  <div *ngFor="let announcement of this.announcements" style="border: 1px solid black; border-radius: 5px; padding: 10px; margin-bottom: 25px;">
    <h5>{{announcement.title}} <small>by {{announcement.postedBy}} (<span [innerHTML]="formatTime(announcement.dateEntered)"></span>)</small></h5>
    <div [innerHTML]="announcement.body"></div>
  </div>
</div>
