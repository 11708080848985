import { Component, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import {InfractionsInterface} from '../../../interfaces/mod/infractions.interface';
import {AuthService} from '../../services/auth.service';
import {RestService} from '../../services/rest.service';
import {Papa} from 'ngx-papaparse';
import {ChatsInterface} from '../../../interfaces/telegram/chats.interface';
import {MembersInterface} from '../../../interfaces/telegram/members.interface';
import {WarnsInterface} from '../../../interfaces/mod/warns.interface';
import moment from 'moment';
import {BansInterface} from '../../../interfaces/mod/bans.interface';

@Component({
  selector: 'app-system-import-export',
  templateUrl: './system-import-export.component.html',
  styleUrls: ['./system-import-export.component.scss']
})
export class SystemImportExportComponent implements OnInit {
  messageInfraction = "Choose file to Import InfractionTypes";
  disabledInfraction = true;
  private infractionTypes: InfractionsInterface[];
  private members: MembersInterface[];
  messageMembers = "Choose file to Import Members (id, handle)";
  messageWarns = "Choose file to Import Warns (id?, memberId, staffId, chatId, platform, dateIssued, dateExpires?, infractionType?, message, executed, softWarn, adminNotes?, points, multiplier)";
  private warns: WarnsInterface[];

  infractionTypeTemp: InfractionsInterface[] = new Array<InfractionsInterface>();
  private bans: BansInterface[];
  messageBans = "Choose file to import bans";

  constructor(
    private auth: AuthService,
    private rest: RestService
  ) { }

  ngOnInit(): void {

    this.rest.get('infractions')
      .subscribe(
        (res) => {
          this.infractionTypeTemp = res;
        }
      )
  }

  exportInfractionType() {
      let data: Array<InfractionsInterface> = [
      ];
      this.rest.get('infractions')
        .subscribe(
          (res) => {

            data = res;
            const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
            const header = Object.keys(data[0]);
            let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
            csv.unshift(header.join(','));
            let csvArray = csv.join('\r\n');

            var blob = new Blob([csvArray], {type: 'text/csv' })
            saveAs(blob, "infraction-export.csv");

          },
          (err) => {
            console.log(err);
          }
        )
  }

  exportChats() {
    let data: Array<ChatsInterface> = [
    ];
    this.rest.get('chats')
      .subscribe(
        (res) => {


          data = res;
          const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
          const header = Object.keys(data[0]);
          let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
          csv.unshift(header.join(','));
          let csvArray = csv.join('\r\n');

          var blob = new Blob([csvArray], {type: 'text/csv' })
          saveAs(blob, "chat-export.csv");

        },
        (err) => {
          console.log(err);
        }
      )
  }

  exportWarns() {
    let data: Array<WarnsInterface> = [
    ];
    this.rest.get('warns')
      .subscribe(
        (res) => {


          data = res;
          const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
          const header = Object.keys(data[0]);
          let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
          csv.unshift(header.join(','));
          let csvArray = csv.join('\r\n');

          var blob = new Blob([csvArray], {type: 'text/csv' })
          saveAs(blob, "warns-export.csv");

        },
        (err) => {
          console.log(err);
        }
      )
  }
  exportBans() {
    let data: Array<BansInterface> = [
    ];
    this.rest.get('bans')
      .subscribe(
        (res) => {


          data = res;
          const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
          const header = Object.keys(data[0]);
          let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
          csv.unshift(header.join(','));
          let csvArray = csv.join('\r\n');

          var blob = new Blob([csvArray], {type: 'text/csv' })
          saveAs(blob, "bans-export.csv");

        },
        (err) => {
          console.log(err);
        }
      )
  }

  exportMembers() {
    let data: Array<ChatsInterface> = [
    ];
    this.rest.get('members')
      .subscribe(
        (res) => {


          data = res;
          const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
          const header = Object.keys(data[0]);
          let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
          csv.unshift(header.join(','));
          let csvArray = csv.join('\r\n');

          var blob = new Blob([csvArray], {type: 'text/csv' })
          saveAs(blob, "members-export.csv");

        },
        (err) => {
          console.log(err);
        }
      )
  }

  submitInfractionImport(){
  }

  submitMemberImport(){
    function sleep(time, callback) {
      var stop = new Date().getTime();
      while(new Date().getTime() < stop + time) {
        ;
      }
      callback();
    }
    for (let i = 0; i < this.members.length; i++) {
      this.rest.post('members', this.members[i])
        .subscribe(
          (res) => {
            console.log("/members", "Posted "+this.members[i].id)
          },
          (err) => {
            console.log(err);
          }
        )
      sleep(50, function () {})
    }
  }

  importMembers(files: FileList) {

    this.members = new Array<MembersInterface>();
    if(files && files.length > 0) {
      let file: File = files.item(0);
      console.log(file.name);
      console.log(file.size);
      console.log(file.type);

      let reader: FileReader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
        let csv: string = reader.result as string;
        console.log(csv);
        let papa: Papa = new Papa();
        papa.parse(csv, {
          complete: (result) => {
            console.log('Parsed', result);
            for (let i = 1; i < result.data.length; i++) {
              let x: Array<string> = result.data[i];
              this.messageInfractionRouter(x[0]);
              this.members.push({
                id: x[0],
                handle: x[1]
              } as MembersInterface);
            }
            this.messageMembers = "Ready to import.";
            this.disabledInfraction = false;
          }
        })

      }
    }
  }

  importWarns(files: FileList) {}

  importBans(files: FileList) {}

  messageInfractionRouter(s: string) {
    this.messageInfraction = s;
  }

  importInfractionTypes(files: FileList) {}

  submitWarns() {
    function sleep(time, callback) {
      var stop = new Date().getTime();
      while(new Date().getTime() < stop + time) {
        ;
      }
      callback();
    }
    for (let i = 0; i < this.warns.length; i++) {
      this.rest.post('warns', this.warns[i])
        .subscribe(
          (res) => {
            console.log("Posted Warn "+i);
          },
          (err) => {
            console.log(err);
          }
        )
      sleep(50, function () {})
    }
  }

  submitBans() {
    function sleep(time, callback) {
      var stop = new Date().getTime();
      while(new Date().getTime() < stop + time) {
        ;
      }
      callback();
    }
    for (let i = 0; i < this.bans.length; i++) {
      this.rest.post('bans', this.bans[i])
        .subscribe(
          (res) => {
            console.log("Posted Ban "+i);
          },
          (err) => {
            console.log(err);
          }
        )
      sleep(50, function () {})
    }
  }
}
