<p>These terms regulate the use of hypnofurs.org and it's components. You can view these terms any time at <a href="http://hypnofurs.org/tos/">http://hypnofurs.org/tos/</a>.</p>

<p>For the regulating document of the Chat Network, please visit <a href='https://hypnofurs.org/coc/'>https://hypnofurs.org/coc/</a>.</p>

<!-- <p>Code of Conduct / Terms of Service / Acceptable Upload Policy</p> -->

<h3 class='ques'>Terms of Service <small>Last Updated: 2021/02/11</small></h3>

<h3 id='privilages'>1. Access and User Privilages <a href="/tos/#privilages"><span class="glyphicon glyphicon-link" aria-hidden="true"></span></a></h3>
"Privileges" are defined as that of which is granted by default and sustained with compliance with regulations, but may be revoked if regulations are breached.
<ol>

  <li>Users have the privilage of accessing content hosted on this site via approved methods</li>
  <li>Users have the privilage of being granted access based on set user levels of permission.</li>
  <li>Users have the privilage of utilizing services provided by the network via approved methods</li>
  <li>Users have the privilage to upload content created by themselves as governed by the Acceptable Upload Policy</li>
  <li>Users have the privilage to utilize reporting and other functions that provide for assistance from the Network Administration on an as-needed basis</li>

</ol>

<h3 id='obligations'>2. User Obligations <a href="/tos/#obligations"><span class="glyphicon glyphicon-link" aria-hidden="true"></span></a></h3>
"Obligations" are defined as that of which must be fullfilled. Failure to do so will result in disciplanary action up to and including removal from groups or the network.
<ol>
  <li>Users must be over the age of 18 in order to use our Services.</li>
  <li>Users have the obligation to follow Network Regulations (See the Code of Conduct and Acceptable Upload Policy)</li>
  <li>Users have the obligation to understand all regulating documents and rules for the systems they choose to access</li>
  <li>Users have the obligation to take full responsibility for any and all content they post.</li>
  <li>Users have the obligation to conduct themselves in a polite and civil manner.</li>
  <li>Users have the obligation to follow all regulations outlined in the Children's Online Privacy Protection (COPPA) Rule (16 CFR Part 312). Any Part Violation of this Rule is subject to an immediate Level 4 Disciplinary Action.</li>
  <li>Users are forbidden from producing "hate speech", harassing statements or violent personal statements regardless of intention. "Hate Speech" is defined as any statement that incites, or conveys hatred against specific groups based off of race, gender, sexual preference, employment, or beliefs.</li>
  <li>Users are forbidden from engaging in flaming, baiting, or otherwise attempting to troll other users or administrators.</li>
  <li>Users are forbidden from posting potentially damaging imagery content such as real-life death or torture, unless expressly for educational discussion.</li>
  <li>Users are forbidden from posting illegal content. Definitions of Illegal Content are derived from the United States Code.</li>
  <li>Users are forbidden from posting material defined as 'Spam' See: <a href="https://hypnofurs.org/coc/#Spamming-Trolling-or-Drama">https://hypnofurs.org/coc/#Spamming-Trolling-or-Drama</a></li>
  <li>Users are forbidden from behaving in a malicious manner with regards to User Interaction</li>
  <li>Users are forbidden from behaving in a malicious manner with regards to Network Infastructure</li>
  <li>Users are forbidden from attempting to game or "rule lawyer" a decision made by Network Administration or Staff.</li>
</ol>

<h3 id='advertising'>3. Advertising <a href="/tos/#advertising"><span class="glyphicon glyphicon-link" aria-hidden="true"></span></a></h3>
<ol>
  <li>Advertising the selling of products is prohibited without prior approval from a Network Administrator or Network Moderator</li>
</ol>

<h3 id='collecting'>4. Collecting User Data <a href="/tos/#collecting"><span class="glyphicon glyphicon-link" aria-hidden="true"></span></a></h3>

<p>We collect some personal and/or identifiable information through cookies and standard server interaction methods for authentication and verification purposes on this site. We do not share this data with any outside organizations and use this data soley for application protection purposes</p>

<h3 id='moderation'>5. Administration and Moderation of Service <a href="/tos/#moderation"><span class="glyphicon glyphicon-link" aria-hidden="true"></span></a></h3>
<ol>
  <li>While we try to guarantee that every member is treated with the correct disciplinary action, we reserve the right to make any decision we believe is necessary to address the situation at hand up to and including, but not limited to, banning someone without warning.</li>

  <li>The views, opinions, or anything thereof discussed on this site and forums are by no means the views, opinions, or anything thereof supported by the HypnoFurs Community Staff. While we will try our best to moderate and delete any troublesome content, we may not be held responsible for any content that is posted.</li>

  <li>When acting on behalf of the HypnoFurs Community Staff Team; Moderators, Administrators, and Users should expect a level of maturity and responsibility from all parties. Failure to behave in a civilized, mature manner could result in negative action on the infringing parties.</li>


</ol>

<h3 id='disciplinary'>6. Disciplinary Actions <a href="/tos/#disciplinary"><span class="glyphicon glyphicon-link" aria-hidden="true"></span></a></h3>
<p>The following is a scaffolding of actions that Network Administrators will issue based on the severity of the offense. This scaffolding is for use in general disciplinary action and the application of additional measures can be approved by any Top-Level Network Administrator. <b>Note that this scaffolding of disciplinary actions applies only to this website, and not to the Chat Network.</b></p>

<ul>
  <li>Level 0 - Verbal Warning Issued to User</li>
  <li>Level 1 - Warning Issued to User, Actions can include up to a temporary mark being placed on the User's Standing.</li>
  <li>Level 2 - 2nd Warning Issued to User, User's Standing Devalued for 1 week</li>
  <li>Level 3 - 3rd Warning Issued to User, User's Standing Devalued for 1 month, Actions can include Chat Network Disciplinary Action</li>
  <li>Level 4 - User banned from utilizing network services for a fixed period, User's Standing Permenantly Devalued, Actions can Include Chat Network Disciplinary Action</li>
  <li>Level 5 - User Banned Permenantly from Utilizing Network Services, Actions can Include Chat Network Disciplinary Action</li>
  <li>Level 6 - Full Network Removal</li>
</ul>

<h3 id="data-rights">7. Your Rights for Data Control</h3>
<p>We respect the fact that your personal information is your information, and we'll make it easy for you to manage and update your personal details.</p>
<ol>
  <li><b>Your right to rectification</b> - You have a right to rectify, update or complete any inaccurate personal data, pending verification of your identification. Most of this can be accomplished by going to your "Account Management" and making any changes there. For instance, if your contact details change, or if you spot any errors in the information we hold about you, we recommend starting with your "Account Management". If, after visiting your "Account Management", you are still unable to rectify the information, please contact us directly by contacting a memeber of administration or e-mailing us at <a href='mailto:myinfo@hypnofurs.org'>myinfo@hypnofurs.org</a>.</li>
  <li><b>Your right of access</b> - You have the right to obtain a copy of all personal data that HypnoFurs.org holds about you, such as personal details or correspondence, generally, within a 30 day time limit and free of charge. You may limit the amount of data that you would like to obtain if you do not wish to access all of the records. Requests for your rights of access can be sent to <a href='mailto:myinfo@hypnofurs.org'>myinfo@hypnofurs.org</a></li>
  <li><b>Your right to move your data (portability)</b> - Under certain circumstances you can request that a data collector move your data to another service provider. This is not an automatic right, and because HypnoFurs provides unique Services, it may not be able to honor your right to data portability. <a href='mailto:myinfo@hypnofurs.org'>myinfo@hypnofurs.org</a></li>
  <li><b>Your right to object to processing</b> - You have to right to request that HypnoFurs stop certain data processing activities that involve processing your personal data. This isn't an automatic right, and HypnoFurs' ability to restrict processing will depend on the type of data that for which you are making this request. Requests for your personal information can be sent to <a href='mailto:stop@hypnofurs.org'>stop@hypnofurs.org</a></li>
  <li><b>You have a right request deletion</b> - You have a right to request that HypnoFurs delete your personal data that it holds. This isn't an automatic right, and what HypnoFurs is able to delete will depend on the type of data that we hold about you, whether it was obtained via consent, what purpose it serves, and whether HypnoFurs has an obligation to keep the data, among other things. Requests for deletions can be sent to <a href='mailto:deleteme@hypnofurs.org'>deleteme@hypnofurs.org</a></li>
  <li><b>You have a right to lodge a complaint with a Supervisory Authority</b> - If you feel that HypnoFurs is not processing your personal data in a manner that complies with the GDPR, you have a right to lodge a complaint with the supervisory authority of your country of residence.</li>
  <li><b>You have a right to withdraw your consent</b> - For activities where you have provided consent to HypnoFurs, you have a right to withdraw your consent at any time by contacting us at <a href='mailto:info@hypnofurs.org'>info@hypnofurs.org</a> or contacting a member of administration. Note that where you remove consent we may not be able to offer you continued access to the Services. However, if the consent relates to activities that are not strictly necessary for a functional user experience, your withdrawal will not affect your ability to use and access the Services.</li>
</ol>
<em>For all requests 1 thru 5 mentioned above, there is a 30 day limit to which HypnoFurs must respond by, that may be extended in certain situations. Also, you will need to give adequate proof of your identity in order for HypnoFurs to identify you before any data is released.</em>
<hr>

<p>These rules are subject to change at any time. They are not, however, subject to loose interpretation. If something seems unclear with the presentation of the rules or the meaning of the rules it should be brought to the attention of the Administration, both Moderator and Member alike.</p>
<p>If you do not agree with these terms, please do not register or use this Service. If you wish to close your account, please contact us at info[at]hypnofurs.org.</p>
