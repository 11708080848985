import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from '../../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthAgreementGuard implements CanActivate {
  constructor(private auth: AuthService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.auth.isLoggedIn()){
      if(!this.auth.authorizedUser.account.acceptPriv || !this.auth.authorizedUser.account.acceptAup || !this.auth.authorizedUser.account.acceptTos) {
        return true;
      }else{
        this.auth.navigate('/');
        return false;
      }
    }else{
      this.auth.navigate('/login');
      return false;
    }
  }

}
