import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Component({
  selector: 'app-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.scss']
})
export class CreditsComponent implements OnInit {

  licenseContent: any = "Please Wait...";


  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.http.get('/3rdpartylicenses.txt', {headers: new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8'), responseType: 'text'})
      .subscribe(
        (res) => {
          this.licenseContent = res;
        }
      )
  }

}
