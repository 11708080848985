import { Component, OnInit } from '@angular/core';
import {MembersInterface} from '../../../interfaces/telegram/members.interface';
import {RestService} from '../../services/rest.service';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-admin-members',
  templateUrl: './admin-members.component.html',
  styleUrls: ['./admin-members.component.scss']
})
export class AdminMembersComponent implements OnInit {
  selection: string = "";
  disabled = false;
  loading = false;
  members: MembersInterface[];
  optionsHidden = false;

  constructor(public rest: RestService, public auth: AuthService) { }

  ngOnInit(): void {
  }

  fetchMembers($event: Event) {
    this.optionsHidden = false;
    //console.log($event);
    if(($event.target as HTMLInputElement).value.length >= 4){
      this.rest.get('members/?search='+($event.target as HTMLInputElement).value)
        .subscribe(
          (res) => {
            console.log(res);
            this.members = res;
          },
          (err) => {
            console.log(err);
          }
        )
    }else{
      this.members = [];
    }
  }

  loadMember(member: MembersInterface) {
    this.optionsHidden = true;
    this.auth.navigate("/admin/members/detail/"+member.id);
  }
}
