import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  tokenGetter(): string {
    let n = localStorage.getItem("access_token");
    if(n === ""){
      return "";
    }
    return "Bearer "+localStorage.getItem("access_token");
  }

  constructor(public http: HttpClient) { }

  post(path: string, data: any): Observable<any> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.tokenGetter()
      })
    }
    return this.http.post<any>(environment.baseUrl + path, data, options)
  }
  get(path: string): Observable<any>{
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.tokenGetter()
      })
    }
    return this.http.get<any>(environment.baseUrl+path, options)
  }
  patch(path: string, data: any): Observable<any>{
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.tokenGetter()
      })
    }
    return this.http.patch<any>(environment.baseUrl+path, data, options)
  }
}
