import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {AccountInterface} from '../../../interfaces/site/account.interface';
import {
  bellCurveIcon,
  ClarityIcons,
  dashboardIcon,
  exclamationTriangleIcon,
  eyeIcon,
  heartBrokenIcon,
  userIcon,
  usersIcon
} from '@cds/core/icon';
import {RestService} from '../../services/rest.service';
import {ChatsInterface} from '../../../interfaces/telegram/chats.interface';
import {StaffsInterface} from '../../../interfaces/telegram/staffs.interface';
import {scriptExecuteIcon} from '@cds/core/icon/shapes/script-execute';
import {announcementIcon} from '@cds/core/icon/shapes/announcement';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

  collapsed = false;

  chats: ChatsInterface[];

  constructor(public auth: AuthService, public rest: RestService) {
    this.chats = new Array<ChatsInterface>();

    ClarityIcons.addIcons(userIcon, dashboardIcon, usersIcon, exclamationTriangleIcon, heartBrokenIcon, scriptExecuteIcon, bellCurveIcon, announcementIcon, eyeIcon);

  }

  ngOnInit(): void {
    this.rest.get('chats/')
      .subscribe(
        (res) => {
          console.log(res);
          this.chats = res;
        },
        (err) => {
          console.log(err);
        }
      )
  }

  findMe(staff: StaffsInterface[], telegramId: string) {
    console.log(staff);
    for (let i = 0; i < staff.length; i++) {
      if(staff[i].member === telegramId){
        return staff[i].rank
      }
    }
  }
}
