<h3 class='ques'>HypnoFurs.org Privacy Policy <small>Effective: 2021/02/11</small></h3>

<p>Protecting your private information is our priority. This Statement of Privacy applies to https://hypnofurs.org/, BalanceModBot, and HypnoFurs Guardian and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to HypnoFurs include https://hypnofurs.org/ and HypnoFurs. The HypnoFurs website is a community platform site. By using the HypnoFurs website, you consent to the data practices described in this statement. This Privacy Policy does not affect Telegram or Discord community discussion groups. </p>

<p>Thank you for choosing to be part of our community at HypnoFurs (“we”, “us”, or “our”). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy , or our practices with regards to your personal information, please contact us at info@hypnofurs.org.</p>

<p>When you visit our website https://hypnofurs.org, and use our services, you trust us with your personal information. We take your privacy very seriously. In this privacy policy, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you do not agree with, please discontinue use of our Sites and our services.</p>

<p>This privacy policy applies to all information collected through our website (such as https://hypnofurs.org), and/or any related services, sales, marketing or events (we refer to them collectively in this privacy policy as the "Services", "Chat Rooms", "Servers", "Computer Systems").  This Privacy Policy does not apply to the Telegram or Discord community discussion groups.</p>

<h4>1. Collection of your Personal Information</h4>
<p>In order to better provide you with products and services offered on our Site, HypnoFurs may collect personally identifiable information, such as your:</p>
<ul>
  <li>First and Last Name</li>
  <li>Mailing Address</li>
  <li>E-mail Address</li>
  <li>Date of Birth</li>
  <li>Gender</li>
  <li>Telegram Information</li>
</ul>

<p>HypnoFurs may also collect anonymous demographic information, which is not unique to you, such as your:</p>
<ul>
  <li>Age</li>
  <li>Gender</li>
  <li>Race</li>
</ul>
<p>Please keep in mind that if you directly disclose personally identifiable information or personally sensitive data through HypnoFurs's public message boards, this information may be collected and used by others. </p>

<p>We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services available on the Site. These may include: (a) registering for an account on our Site; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering an purchasing products and services on our Site. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.</p>

<h4>2. Use of your Personal Information </h4>
<p>HypnoFurs collects and uses your personal information to operate its website(s) and deliver the services you have requested.</p>

<p>Personally identifiable information is stored and used exclusively for HypnoFurs.org and HypnoFurs services that include but are not limited to services that bear the "Screening Required" icon: 📑.</p>

<p>HypnoFurs may also use your personally identifiable information to inform you of other products or services available from HypnoFurs and its affiliates.</p>

<h4>3. Sharing Information with Third Parties </h4>
<p>HypnoFurs does not sell, rent or lease its customer lists to third parties.</p>

<p>HypnoFurs may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to HypnoFurs, and they are required to maintain the confidentiality of your information.</p>

<p>HypnoFurs may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on HypnoFurs or the site; (b) protect and defend the rights or property of HypnoFurs; and/or (c) act under exigent circumstances to protect the personal safety of users of HypnoFurs, or the public.</p>

<h4>4. Tracking User Behavior </h4>
<p>HypnoFurs may keep track of the websites and pages our users visit within HypnoFurs, in order to determine what HypnoFurs services are the most popular. This data is used to deliver customized content and advertising within HypnoFurs to customers whose behavior indicates that they are interested in a particular subject area. </p>

<h4>5. Automatically Collected Information</h4>
<p>Information about your computer hardware and software may be automatically collected by HypnoFurs. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the HypnoFurs website.</p>

<h4>6. Use of Cookies</h4>
<p>The HypnoFurs website may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.</p>

<p>One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize HypnoFurs pages, or register with HypnoFurs site or services, a cookie helps HypnoFurs to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same HypnoFurs website, the information you previously provided can be retrieved, so you can easily use the HypnoFurs features that you customized.</p>

<p>You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the HypnoFurs services or websites you visit.</p>

<h4>7. Security of your Personal Information </h4>
<p>HypnoFurs secures your personal information from unauthorized access, use, or disclosure. HypnoFurs uses the following methods for this purpose: </p>
<ul>
  <li>SSL Protocol </li>
  <li>JWT Tokenization </li>
</ul>

<p>When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol. </p>

<p>We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed. </p>

<h4>8. Children Under Thirteen </h4>
<p>HypnoFurs does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website. </p>

<h4>9. Children Under Eighteen</h4>
<p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we have collected from children under age 18, please contact us at <a href="mailto:yuukari@hypnofurs.org">yuukari@hypnofurs.org</a>.</p>

<h4>10. Users Outside of the United States</h4>
<p>Our computer systems and third party hosting provider systems are currently based in the United States and may be located in other countries, so your personal data will be processed by us in the U.S. and other countries where data protection and privacy regulations may not offer the same level of protection as in other parts of the world, such as the European Union. If you create a user account with the Site as a visitor from outside the United States, by using the Site you agree to this Privacy Policy and you consent to the transfer of all such information to the United States, which may not offer an equivalent level of protection of that required in the European Union or certain other countries, and to the processing of that information as described in this Privacy Policy.</p>

<h4>11. Your Rights Regarding Data Processing</h4>
<p>You can review your Data Processing Rights as a User of HypnoFurs.org Services in our Terms of Service, available Online at: <a href="/tos/#data-rights">https://hypnofurs.org/tos/#data-rights</a></p>

<h4>12. Specific California Residents Privacy Rights</h4>
<p>California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</p>
<p>If you are under 18 years of age, reside in California, and have a registered account with the Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below, and include the Username and Telegram Handle associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from our systems. <a href="mailto:myinfo@hypnofurs.org">myinfo@hypnofurs.org</a></p>

<h4>13. Controls for Do-Not-Track Features</h4>
<p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy policy.</p>

<h4>a. Changes to this Statement</h4>
<p>HypnoFurs reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our site, and/or by updating any privacy information on this page. Your continued use of the Site and/or Services available through this Site after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy. </p>

<h4>b. Contact Information</h4>
<p>HypnoFurs welcomes your questions or comments regarding this Statement of Privacy. If you believe that HypnoFurs has not adhered to this Statement, please contact HypnoFurs at: <a href="mailto:yuukari@hypnofurs.org">yuukari@hypnofurs.org</a> with the subject "Privacy Policy"</p>

<h4>c. Data Protection Contact Information</h4>
<p>If you have questions or comments about this policy, you may contact our Data Protection Officer (DPO), Yuukari Shishome, by email at <a href="mailto:yuukari@hypnofurs.org">yuukari@hypnofurs.org</a>.</p>

<p>Email Address:
  info[at]hypnofurs.org</p>

<p>Effective as of January 9th, 2020</p>
