import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from '../../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(private auth: AuthService) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    //todo: better admin checks.

    if(this.auth.isLoggedIn() && ((this.auth.authorizedUser.account.isAdmin || this.auth.authorizedUser.account.isMod) || this.auth.authorizedUser.account.is_staff)){
      return true;
    }else{
      this.auth.navigate('/login');
      return false;
    }
  }

}
