import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AdminGuard} from '../guards/admin/admin.guard';
import {SystemLayoutComponent} from './system-layout/system-layout.component';
import {SystemDashboardComponent} from './system-dashboard/system-dashboard.component';
import {SystemImportExportComponent} from './system-import-export/system-import-export.component';
import {SystemUsersComponent} from './system-users/system-users.component';
import {SuperAdminGuard} from '../guards/superadmin/superadmin.guard';
import {SystemUsersDetailComponent} from './system-users/system-users-detail/system-users-detail.component';
import {SystemAnnouncementsComponent} from './system-announcements/system-announcements.component';

const routes: Routes = [
  {
    path: 'system',
    component: SystemLayoutComponent,
    canActivate: [SuperAdminGuard],
    children: [
      {
        path: 'dashboard',
        component: SystemDashboardComponent,
        canActivate: [SuperAdminGuard],
      },
      {
        path: 'database',
        component: SystemImportExportComponent,
        canActivate: [SuperAdminGuard],
      },
      {
        path: 'announcements',
        component: SystemAnnouncementsComponent,
        canActivate: [SuperAdminGuard],
      },
      {
        path: 'users',
        component: SystemUsersComponent,
        canActivate: [SuperAdminGuard],
        children: [

        ]
      },
      {
        path: 'users/account/:id',
        component: SystemUsersDetailComponent,
        canActivate: [SuperAdminGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class SystemRoutingModule { }
