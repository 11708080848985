import { Injectable } from '@angular/core';
import {RestService} from './rest.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {JwtHelperService} from '@auth0/angular-jwt';
import {JwtInterface} from '../../interfaces/site/jwt.interface';
import {AccountInterface} from '../../interfaces/site/account.interface';
import {Router} from '@angular/router';
import { NgZone } from '@angular/core';
import {Observable} from 'rxjs';
import {SnackbarService} from './snackbar.service';
import md5 from 'md5';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  authorizedUser: JwtInterface;
  authorizedUserAccount: AccountInterface;

  loggedIn = false;

  constructor(
    private restService: RestService,
    private http: HttpClient,
    private router: Router,
    public zone: NgZone,
    private snackbar: SnackbarService
  ) {
    console.log("Auth Service is Running");
    this.checkLoggedIn();
  }

  login(username: string, password: string): void {
    this.http.post<any>(environment.baseUrl+'token/', {username: username, password: password})
      .subscribe(
        async (r) => {
          //console.log(r);
          localStorage.setItem("access_token", r.access);
          const helper = new JwtHelperService();
          await this.http.get(environment.baseUrl+'whoami/', {headers: {'Authorization': 'Bearer '+r.access}})
            .subscribe(
              (r2: JwtInterface) => {
                this.authorizedUser = r2;
                this.authorizedUserAccount = this.authorizedUser.account;
                localStorage.setItem('authorizedUser', JSON.stringify(this.authorizedUser));
                localStorage.setItem('authorizedUserAccount', JSON.stringify(this.authorizedUserAccount));
                //console.log(helper.decodeToken(r.access));
                this.snackbar.open("Logged in as " + this.authorizedUserAccount.username)
                // we now have a user, lets get their account
                this.getNewAccount();
                this.zone.run(() => {
                  this.router.navigate(['/'])
                });
              }
            )
        },
        (err) => console.log(err)
      )
  }

  telegramLogin(loginData: any){
      // first we check for accounts.

            type TelegramCredentials = {
              telegramPayload: any
            };

            // compile a login payload
            const actPayload: Object = {
              telegramPayload: loginData,
            }
            console.log(actPayload);
            this.restService.post('telegram/', actPayload)
              .subscribe(
                async (res) => {
                  // this is a token element.
                  localStorage.setItem("access_token", res.access);
                  let helper: JwtHelperService = new JwtHelperService();
                  await this.http.get(environment.baseUrl + 'whoami/', {headers: {'Authorization': 'Bearer ' + res.access}})
                    .subscribe(
                      (r2: JwtInterface) => {
                        this.authorizedUser = r2;
                        this.authorizedUserAccount = this.authorizedUser.account;
                        localStorage.setItem('authorizedUser', JSON.stringify(this.authorizedUser));
                        localStorage.setItem('authorizedUserAccount', JSON.stringify(this.authorizedUserAccount));
                        this.snackbar.open("Logged in via telegram as " + this.authorizedUserAccount.username)
                        this.getNewAccount();
                        this.zone.run(() => {
                          this.router.navigate(['/'])
                        });
                      }
                    )
                },
                (err) => {
                  console.log("telegram.login", "error in procesing")
                  alert(err);
                }
              )
}

  navigate(path: string){
    this.zone.run( () => { this.router.navigate([path])});
  }

  logout(){
    console.log("auth.service", "Anonymous, Logged Out Due to Expiry");
    localStorage.setItem("access_token","");
    localStorage.setItem("authorized_user_account","")
    this.authorizedUser = null;
    this.authorizedUserAccount = null;
    this.loggedIn = false;
    this.snackbar.open("Logged out successfully.")
    this.zone.run( () => { this.router.navigate(['/'])});
  }

  getToken(): string {
    return localStorage.getItem("access_token");
  }

  getDecodedToken(): JwtInterface {
    let token: string = localStorage.getItem("access_token");
    const helper = new JwtHelperService();
    return helper.decodeToken(token)
  }

  loginExpired(): boolean {
    let token: string = localStorage.getItem("access_token");
    const helper = new JwtHelperService();
    return helper.isTokenExpired(token);
  }

  getNewAccount(): AccountInterface{
    this.checkLoggedIn();
    return this.authorizedUserAccount;
  }

  isLoggedIn(){
    return this.authorizedUser !== null;
  }

  isUserAuthentic(): boolean{
    let temp: AccountInterface = JSON.parse(localStorage.getItem("authorizedUserAccount"));
    let temp2: JwtInterface = JSON.parse(localStorage.getItem("authorizedUser"));
    //console.table([temp, this.authorizedUserAccount])
    //console.table([temp2, this.authorizedUser])
    if(this.authorizedUserAccount === undefined && this.authorizedUser === undefined){
      return false;
    }else{
      if(md5(JSON.stringify(this.authorizedUserAccount)) === md5(JSON.stringify(temp)) && md5(JSON.stringify(this.authorizedUser)) == md5(JSON.stringify(temp2))){
        return true
      }
    }

    return false;
  }

  async checkLoggedIn() {
    const helper = new JwtHelperService();
    let token: string = localStorage.getItem("access_token");
    if (token === null) {
      console.log("auth.service", "Anonymous");
      localStorage.setItem("access_token", "");
      this.authorizedUser = null;
      this.authorizedUserAccount = null;
      this.loggedIn = false;
    } else {
      if (this.loginExpired()) {
        console.log("auth.service", "Anonymous, Logged Out Due to Expiry");
        localStorage.setItem("access_token", "");
        this.authorizedUser = null;
        this.authorizedUserAccount = null;
        this.loggedIn = false;
      } else {

        this.authorizedUser = JSON.parse(localStorage.getItem("authorizedUser"));
        this.authorizedUserAccount = JSON.parse(localStorage.getItem("authorizedUserAccount"));

        await this.http.get(environment.baseUrl + 'whoami/', {headers: {'Authorization': 'Bearer ' + token}})
          .subscribe(
            (r2: JwtInterface) => {
              this.authorizedUser = r2;
              this.authorizedUserAccount = this.authorizedUser.account;
              if(!this.isUserAuthentic()){
                this.logout();
              }
            }
          )
      }
    }
  }

  awaitAccount(): Observable<any> {
    return this.restService.get('accounts/byUser/'+this.authorizedUserAccount.id)
  }
}
