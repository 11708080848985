import { Component, OnInit } from '@angular/core';
import {AuthService} from '../services/auth.service';
import {RestService} from '../services/rest.service';
import {faDiscord, faTelegram} from '@fortawesome/free-brands-svg-icons';
import {AnnouncementsInterface} from '../../interfaces/site/announcements.interface';
import moment from 'moment';

@Component({
  selector: 'app-front',
  templateUrl: './front.component.html',
  styleUrls: ['./front.component.scss']
})
export class FrontComponent implements OnInit {

  announcements: AnnouncementsInterface[] = [];

  constructor(public authService: AuthService, private restService: RestService) {}

  ngOnInit(): void {
    this.fetchAnnouncements();
  }
  fetchAnnouncements() {
    this.restService.get('announcements/')
      .subscribe(
        (res) => {
          this.announcements = res;
        },
        (err) => {
          console.log(err);
        }
      )
  }
  formatTime(dateEntered: string) {
    return moment(dateEntered).fromNow();
  }

  faDiscord = faDiscord;
  faTelegram = faTelegram;

}
