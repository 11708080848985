import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from '../../services/auth.service';
import {AccountInterface} from '../../../interfaces/site/account.interface';

@Injectable({
  providedIn: 'root'
})
export class AnonAuthAgreementsGuard implements CanActivate {
  constructor(private auth: AuthService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.auth.isLoggedIn()){
      if(!this.auth.authorizedUser.account.acceptPriv || !this.auth.authorizedUser.account.acceptAup || !this.auth.authorizedUser.account.acceptTos) {
        this.auth.navigate('/agreements');
        return false;
      }
      return true;
    }else{
      return true;
    }
  }

}
