<p>This policy regulates the use of hypnofurs.org and it's components. You can view these terms any time at <a href="http://hypnofurs.org/aup/">http://hypnofurs.org/aup/</a>.</p>

<h3 class="ques">Acceptable Upload Policy <small>Updated: 2021/02/11</small></h3>

<h4 id="1">1. Content Guidelines</h4>
<h5 id="1.1">1.1 Ratings</h5>
<p>Content Uploaded to this site must be appropriately rated utilizing the system provided.</p>
<clr-accordion>
<clr-accordion-panel>
  <clr-accordion-title>Tame Content Guidelines</clr-accordion-title>
  <clr-accordion-content *clrIfExpanded>
    <div class="row hidden" id='tame-guidelines'>
      <div class="col-md-3">
        <b>Language</b>
      </div>
      <div class="col-md-9">
        <p>May contain some profanity; however, language is not used in a sexual context. Dialogue may be suggestive, contain sexual innuendo, discussion of adult themes, and the expression of views and opinions that users may find offensive, disrespectful, or controversial.</p>
      </div>
      <div class="col-md-3">
        <b>Sexual Situations</b>
      </div>
      <div class="col-md-9">
        <p>Free of sexual or excessive fetish themes with exception of brief displays of affection, such as a kiss or hug.</p>
      </div>
    </div>
  </clr-accordion-content>
</clr-accordion-panel>
  <clr-accordion-panel>
  <clr-accordion-title>Mature Content Guidelines</clr-accordion-title>
  <clr-accordion-content *clrIfExpanded>
    <div class="row hidden" id='mature-guidelines'>
      <div class="col-md-3">
        <b>Language</b>
      </div>
      <div class="col-md-9">
        <p>Contains persistent use of profanity, sexual discussion, or other such language that otherwise would invoke arousal. This excludes sexual discussion through the lens of fetishistic topics. (A good rule of thumb: If it's a fetish, label it as Adult)</p>
      </div>
      <div class="col-md-3">
        <b>Sexual Situations</b>

      </div>
      <div class="col-md-9">
        <p>Contains mild sexual activity or themes such as implied sex acts, light or comedic sexual fetish references or behavior, or mild sexual situations or discussion.</p>
      </div>
    </div>
  </clr-accordion-content>
</clr-accordion-panel>
  <clr-accordion-panel>
  <clr-accordion-title>Adult Content Guidelines</clr-accordion-title>
  <clr-accordion-content *clrIfExpanded>
    <div class="row hidden" id='adult-guidelines'>
      <div class="col-md-3">
        <b>Language</b>
      </div>
      <div class="col-md-9">
        <p>Contains vulgar dialogue that is sexually explicit and/or contains reference to fetishes not common outside of general parlance (With the noted exclusion of Hypnosis.)</p>
      </div>
      <div class="col-md-3">
        <b>Sexual Situations</b>
      </div>
      <div class="col-md-9">
        <p>Contains explicit sexual content such as sex acts, explicit sexual situations or discussion, and extreme fetishes.</p>
      </div>
    </div>
  </clr-accordion-content>
</clr-accordion-panel>
</clr-accordion>
<h5 id='1.2'>1.2 Submission Detail</h5>
<p>Ratings, Details, and Categories are used to define files as they appear in the search engine. Abuse of these (misleading, inaccurate, or false) is prohibited. Submission file type should correspond to submission type.</p>
<h4 id='2'>2. All Media</h4>
<h5 id='2.1'>2.1 Content Not Made By You</h5>
<p>Content that was not made by uploader is prohibited, unless part of a derivative work or granted permission by the copyright holder of the work published. All permissions, if required, must be cited in the content's description.</p>
<h5 id='2.2'>2.2 Selling Work</h5>
<p>Selling work, or advertising the selling of work through file content, description, or otherwise is prohibited.</p>
<h5 id='2.3' class='text-warning'>2.3 Content Involving Minors</h5>
<p>Content featuring minors is prohibited when sexual activity is present. Minors are real or fictional humanoids with a childlike body or younger than 18 years old, and any adolescent animals. Files with these themes are prohibited from upload and hosting on this site and will be met with swift, harsh punishment up to and including the involvement of local law enforcement officials.</p>
<h5 id='2.4'>2.4 Content Lacking Artistic Merit</h5>
<p>Content lacking artistic merit (such as content-less audio files or empty documents) is prohibited.</p>
<h5 id='2.5' class='text-warning'>2.5 Plagarized or Stolen Content</h5>
<p>Plagiarized, or stolen content is prohibited. Authors whom feel that this site is distributing or hosting content that is their intellectual property without consent are encoraged to file for a DCMA takedown at <a href="/dmca/">http://hypnofurs.org/dmca/</a>.</p>
<h4 id='3'>3. Locking and Moderation Policy</h4>
<h5 id='3.1'>3.1 Content Locking &amp; Review</h5>
<p>Upon upload to the platform, files will be locked and subject to a cusory review of it's description, title, filing, and contents to ensure that it meets Acceptable Upload Policy Guidelines. We do not review file contents beyond the scope of our Content Gudelines outlined in Section 1 and our media guidelines in Section 2.</p>
<h5 id='3.2'>3.2 As-Is</h5>
<p>Files hosted on this platform are considered as-is available to the public and are not endorsed by the HypnoFurs Network or it's Staff. By downloading files from this service you agree that the files are provided as-is and are aware that the file contents are the sole responsibility of the Author.</p>
<hr>

<p>These rules are subject to change at any time. They are not, however, subject to loose interpretation. If something seems unclear with the presentation of the rules or the meaning of the rules it should be brought to the attention of the Administration, both Moderator and Member alike.</p>
<p>If you do not agree with these terms, please do not register or use this Service. If you wish to close your account, please contact us at info[at]hypnofurs.org.</p>
