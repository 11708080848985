import { Component, OnInit } from '@angular/core';
import {RestService} from '../services/rest.service';
import {AuthService} from '../services/auth.service';
import {TelegramLoginService} from '../services/telegram-login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(public authService: AuthService, private restService: RestService, public loginViaTelegram: TelegramLoginService) {

  }

  loginCredentials = {
    email: '',
    password: ''
  }

  /**
  basicSignUpForm = {
    "realm": "system",
    "username": "botAcct",
    "email": "bot@hypnofurs.org",
    "emailVerified": true,
    "password": "changeMe2020"
  }
  **/
  ngOnInit(): void {
  }

  doLogin() {
    this.authService.login(this.loginCredentials.email, this.loginCredentials.password);
  }
}
