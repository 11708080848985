import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import {AdminRoutingModule} from './admin-routing.module';
import {ClarityModule} from '@clr/angular';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { AdminMembersComponent } from './admin-members/admin-members.component';
import {FormsModule} from '@angular/forms';
import { AdminMemberDetailComponent } from './admin-members/admin-member-detail/admin-member-detail.component';
import { AdminChatComponent } from './admin-chat/admin-chat.component';
import {EditorModule} from '@tinymce/tinymce-angular';



@NgModule({
  declarations: [AdminDashboardComponent, AdminLayoutComponent, AdminMembersComponent, AdminMemberDetailComponent, AdminChatComponent],
  imports: [
    CommonModule,
    AdminRoutingModule,
    ClarityModule,
    FormsModule,
    EditorModule
  ]
})
export class AdminModule { }
