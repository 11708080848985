import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {RestService} from '../../services/rest.service';
import {bubbleExclamationIcon, ClarityIcons, dashboardIcon, dataClusterIcon, usersIcon} from '@cds/core/icon';
import {AccountInterface} from '../../../interfaces/site/account.interface';

@Component({
  selector: 'app-system-layout',
  templateUrl: './system-layout.component.html',
  styleUrls: ['./system-layout.component.scss']
})
export class SystemLayoutComponent implements OnInit {
  collapsed = false;

  constructor(
    public auth: AuthService,
    public rest: RestService
  ) {
  }

  ngOnInit(): void {
    ClarityIcons.addIcons(dataClusterIcon, dashboardIcon, usersIcon, bubbleExclamationIcon)
  }

}
