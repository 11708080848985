<div *ngIf="member === undefined">
  <h1 class="text-light">Contacting the API... Please Wait.</h1>
</div>
<div *ngIf="member != undefined">
  <h1 class="text-light">@{{member.handle}} ({{member.id}})</h1>
  <div class="hf-first-content-container container">
    <nav class="subnav" style="background: #333">
      <ul class="nav">
        <li class="nav-item">
          <a class="nav-link" (click)="selectNavigation('overview')" href="javascript://">Overview</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="selectNavigation('record')" href="javascript://">Record</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="navigation = 'activity'" href="javascript://">Activity</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="navigation = 'notes'" href="javascript://">Notes</a>
        </li>
        <li class="nav-item" *ngIf="auth.authorizedUser.account.is_staff" style="background: white !important;">
          <a class="nav-link" (click)="navigation = 'quick-settings'" href="javascript://">Quick Settings</a>
        </li>
      </ul>
    </nav>

    <section *ngIf="navigation === 'overview'">
      <div class="card">
        <div class="text-center">
          <div class="progress top">
            <progress [value]="100 - member.activePoints" max="100"></progress>
          </div>
          <div class="clr-row">
            <div class="clr-col-3 text-right" style="border-right: 1px solid black;">&nbsp;</div>
            <div class="clr-col-3 text-right" style="border-right: 1px solid black;">Ban Threshold</div>
          </div>
        </div>
      </div>

      <h3 class="text-center text-light">{{ 100 - member.activePoints }}/100</h3>
      <div class="clr-row">
        <div class="clr-col-6">
          <h4 class="text-light text-center">Active Warns</h4>
          <clr-datagrid>
            <clr-dg-column>Chat</clr-dg-column>
            <clr-dg-column>Staff Member</clr-dg-column>
            <clr-dg-column>Reason</clr-dg-column>
            <clr-dg-column>Expiry</clr-dg-column>

            <clr-dg-placeholder>No Active Warns. (Inactive Warns Located in Record)</clr-dg-placeholder>

            <clr-dg-row *ngFor="let warn of aWarns">
                <clr-dg-cell>{{warn.chatId}}</clr-dg-cell>
                <clr-dg-cell>{{warn.staffId}}</clr-dg-cell>
                <clr-dg-cell>{{warn.message}}</clr-dg-cell>
                <clr-dg-cell>{{warn.dateExpires}}</clr-dg-cell>
            </clr-dg-row>

          </clr-datagrid>
        </div>
        <div class="clr-col-6">
          <h4 class="text-light text-center">Active Bans</h4>
          <clr-datagrid>
            <clr-dg-column>Chat</clr-dg-column>
            <clr-dg-column>Staff Member</clr-dg-column>
            <clr-dg-column>Reason</clr-dg-column>
            <clr-dg-column>Expiry</clr-dg-column>

            <clr-dg-placeholder>No Active Bans. (Inactive Bans Located in Record)</clr-dg-placeholder>

            <clr-dg-row *ngFor="let ban of aBans">
              <clr-dg-cell>{{ban.chatId}}</clr-dg-cell>
              <clr-dg-cell>{{ban.staffId}}</clr-dg-cell>
              <clr-dg-cell>{{ban.message}}</clr-dg-cell>
              <clr-dg-cell>{{ban.dateExpires}}</clr-dg-cell>
            </clr-dg-row>
          </clr-datagrid>
        </div>
      </div>


    </section>
    <section *ngIf="navigation === 'record'">
      <h3 class="text-light">Infraction Records</h3>

      <h4 class="text-light">Warns</h4>
      <clr-datagrid>
        <clr-dg-column>Chat</clr-dg-column>
        <clr-dg-column>Staff Member</clr-dg-column>
        <clr-dg-column>Reason</clr-dg-column>
        <clr-dg-column>Expiry</clr-dg-column>

        <clr-dg-placeholder>No Warns Recorded.</clr-dg-placeholder>

        <clr-dg-row *ngFor="let warn of warns">
          <clr-dg-cell>{{warn.chat.name}}</clr-dg-cell>
          <clr-dg-cell>{{warn.staff}}</clr-dg-cell>
          <clr-dg-cell>{{warn.message}}</clr-dg-cell>
          <clr-dg-cell>{{warn.dateExpires}}</clr-dg-cell>
        </clr-dg-row>

      </clr-datagrid>

      <h4 class="text-light">Bans</h4>
      <clr-datagrid>
        <clr-dg-column>Chat</clr-dg-column>
        <clr-dg-column>Staff Member</clr-dg-column>
        <clr-dg-column>Reason</clr-dg-column>
        <clr-dg-column>Expiry</clr-dg-column>

        <clr-dg-placeholder>No Bans on Record.</clr-dg-placeholder>

        <clr-dg-row *ngFor="let ban of bans">
          <clr-dg-cell>{{ban.chat}}</clr-dg-cell>
          <clr-dg-cell>{{ban.staff}}</clr-dg-cell>
          <clr-dg-cell>{{ban.message}}</clr-dg-cell>
          <clr-dg-cell>{{ban.dateExpires}}</clr-dg-cell>
        </clr-dg-row>
      </clr-datagrid>

    </section>

  </div>
</div>
