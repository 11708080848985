import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RestService} from '../../../services/rest.service';
import {AccountInterface} from '../../../../interfaces/site/account.interface';
import {User} from '../../../../interfaces/users.interface';
import {SnackbarService} from '../../../services/snackbar.service';
import {MembersInterface} from '../../../../interfaces/telegram/members.interface';

@Component({
  selector: 'app-system-users-detail',
  templateUrl: './system-users-detail.component.html',
  styleUrls: ['./system-users-detail.component.scss']
})
export class SystemUsersDetailComponent implements OnInit {

  public user: AccountInterface;
  public telegramMember: MembersInterface;

  constructor(private route: ActivatedRoute,
              private rest: RestService,
              private snackbar: SnackbarService) {
    let id = this.route.snapshot.params['id'];
    this.rest.get('accounts/'+id+'/')
      .subscribe(
        (res) => {
          console.log(res);
          this.user = res;
          this.rest.get('members/'+(this.user.member)+'/')
            .subscribe(
              (res2) => {
                console.log(res2);
                this.telegramMember = res2;
              },
              (err) => {
                console.log(err);
              }
            )
        },
        (err) => {

        }
      )
  }

  refetchUser(id: string){
    this.rest.get('accounts/'+id)
      .subscribe(
        (res) => {
          this.user = res;
          this.rest.get('members/'+this.user.member)
            .subscribe(
              (res2) => {
                this.telegramMember = res2;
              },
              (err) => {
                console.log(err);
              }
            )
        },
        (err) => {

        }
      )
  }

  ngOnInit(): void {
  }

  doPromote(rank: string) {
    var fragment = {};
    switch(rank){
      case 'isAdmin':
        fragment = {isAdmin: true};
        break;
      case 'isMod':
        fragment = {isMod: true};
        break;
    }
    this.rest.patch('accounts/'+this.user.id+'/', fragment)
      .subscribe(
        (res) => {
          console.log(res);
          this.snackbar.open("User updated. User must login and logout to update.");
          this.refetchUser(this.user.id);
        },
        (err) => {
          console.log(err);
          this.snackbar.open("Change not made. Error occured.");
        }
      )
  }

  doDemote(rank: string) {
    var fragment = {};
    switch(rank){
      case 'isAdmin':
        fragment = {isAdmin: false};
        break;
      case 'isMod':
        fragment = {isMod: false};
        break;
    }
    this.rest.patch('accounts/'+this.user.id+'/', fragment)
      .subscribe(
        (res) => {
          console.log(res);
          this.snackbar.open("User updated. User must login and logout to update.");
          this.refetchUser(this.user.id);
        },
        (err) => {
          console.log(err);
          this.snackbar.open("Change not made. Error occured.");
        }
      )
  }
}
