<div class="hf-first-content-container container">
  <h1>Database</h1>
  <hr>
  <h2>Site</h2>
  <h3>Users /users</h3>
  <h3>Accounts /accounts</h3>
  <hr>
  <h2>Telegram</h2>
  <h3>Chats /chats</h3>
  <button class="btn btn-success" (click)="exportChats()">Export</button> <button class="btn btn-info" [disabled]="true" >Chats can't be Imported</button>
  <h3>Members /members</h3>
  <button class="btn btn-success" (click)="exportMembers()">Export</button> <button class="btn btn-info" [disabled]="disabledInfraction" (click)="submitMemberImport()">Import</button>
  <div class="custom-file">
    <input type="file" class="custom-file-input" id="customFileMembers" name="infractionTypesImport" (change)="importMembers($event.target.files)">
    <label class="custom-file-label" for="customFile">{{messageMembers}}</label>
  </div>
  <hr>
  <h2>Moderation</h2>
  <h3>Warns /warns</h3>
  <button class="btn btn-success" (click)="exportWarns()">Export</button> <button class="btn btn-info" [disabled]="disabledInfraction" (click)="submitWarns()">Import</button>
  <div class="custom-file">
    <input type="file" class="custom-file-input" id="customFileWarns" name="warnsImport" (change)="importWarns($event.target.files)">
    <label class="custom-file-label" for="customFile">{{messageWarns}}</label>
  </div>
  <h3>Bans /bans</h3>
  <button class="btn btn-success" (click)="exportBans()">Export</button> <button class="btn btn-info" [disabled]="disabledInfraction" (click)="submitBans()">Import</button>
  <div class="custom-file">
    <input type="file" class="custom-file-input" id="customFileBans" name="bansImport" (change)="importBans($event.target.files)">
    <label class="custom-file-label" for="customFile">{{messageBans}}</label>
  </div>
  <h3>InfractionTypes /infractions</h3>
  <button class="btn btn-success" (click)="exportInfractionType()">Export</button> <button class="btn btn-info" [disabled]="disabledInfraction" (click)="submitInfractionImport()">Import</button>
  <div class="custom-file">
    <input type="file" class="custom-file-input" id="customFile" name="infractionTypesImport" (change)="importInfractionTypes($event.target.files)">
    <label class="custom-file-label" for="customFile">{{messageInfraction}}</label>
  </div>
  <br>
</div>
