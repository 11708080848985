<div *ngIf="user === undefined">
  <h1>Fetching Account, please wait.</h1>

</div>
<div *ngIf="user !== undefined">
  <h1>{{user.username}}</h1>
  <clr-accordion>
    <clr-accordion-panel>
      <clr-accordion-title>Application ({{user.id}})</clr-accordion-title>
      <clr-accordion-content *clrIfExpanded>
        <em>This content is not editable.</em>
        <div class="row">
          <div class="col-md-6">
            <dl>
              <dt>Account ID</dt>
              <dd>{{user.id}}</dd>
              <dt>Username</dt>
              <dd>{{user.username}}</dd>
            </dl>
          </div>
          <div class="col-md-6">
            <dl>
              <dt>Realm</dt>
              <dd>Application</dd>
            </dl>
          </div>
        </div>
      </clr-accordion-content>
    </clr-accordion-panel>

    <clr-accordion-panel>
      <clr-accordion-title>Account ({{user.id}})</clr-accordion-title>
      <clr-accordion-content *clrIfExpanded>
        <div class="row">
          <div class="col-md-6">
            <dl>
              <dt>Account ID</dt>
              <dd>{{user.id}}</dd>
              <dt>Attached Account</dt>
              <dd>{{user.member}}</dd>
              <dt>Roles</dt>
              <dd>Standard User</dd>
              <dd *ngIf="user.isMod">Moderator <button class="btn btn-link btn-sm" (click)="doDemote('isMod')">(demote)</button></dd>
              <dd *ngIf="user.isAdmin">Admin <button class="btn btn-link btn-sm" (click)="doDemote('isAdmin')">(demote)</button></dd>
              <dd *ngIf="!user.isMod"><button class="btn btn-link btn-sm" (click)="doPromote('isMod')">Promote to Moderator</button></dd>
              <dd *ngIf="!user.isAdmin"><button class="btn btn-link btn-sm" (click)="doPromote('isAdmin')">Promote to Admin</button></dd>
              <dd *ngIf="user.is_staff">API Admin (can't modify)</dd>
              <dd *ngIf="user.is_superuser">Super Admin (can't modify)</dd>
            </dl>
          </div>
          <div class="col-md-6">
            <dl>
              <dt>Accepted Agreements</dt>
              <dd *ngIf="user.acceptTos">Terms of Service</dd>
              <dd *ngIf="user.acceptAup">Acceptable Upload Policy</dd>
              <dd *ngIf="user.acceptPriv">Privacy Policy</dd>
            </dl>
          </div>
        </div>

      </clr-accordion-content>
    </clr-accordion-panel>

    <clr-accordion-panel>
      <clr-accordion-title>Telegram ({{telegramMember.id}})</clr-accordion-title>
      <clr-accordion-content *clrIfExpanded>
        <div class="row">
          <div class="col-md-6">
            <dl>
              <dt>Telegram ID</dt>
              <dd>{{telegramMember.id}}</dd>
              <dt>Handle</dt>
              <dd><a href="https://t.me/{{telegramMember.handle}}" target="_blank">@{{telegramMember.handle}}</a></dd>
            </dl>
          </div>
          <div class="col-md-6">
            <dl>
              <dt>First Name</dt>
              <dd>{{telegramMember.firstName}}</dd>
              <dt>Last Name</dt>
              <dd>{{telegramMember.lastName}}</dd>
            </dl>
          </div>
        </div>
      </clr-accordion-content>
    </clr-accordion-panel>
  </clr-accordion>
</div>
