<div class="hf-first-content-container container">
  <h1 class="text-light">Please accept these agreements to continue using this service.</h1>

  <div *ngIf="auth.authorizedUser.account.acceptTos === false">
    <h4 class="text-light">Terms of Service</h4>
    <app-terms></app-terms>
    <button class="btn btn-success btn-block" (click)="acceptTos()">Accept Terms of Service</button>
    <hr>
  </div>

  <div *ngIf="auth.authorizedUser.account.acceptAup === false">
    <h4 class="text-light">Acceptable Upload Policy</h4>
      <app-acceptable-upload></app-acceptable-upload>
    <button class="btn btn-success btn-block" (click)="acceptAup()">Accept Acceptable Upload Policy</button>
    <hr>
  </div>

  <div *ngIf="auth.authorizedUser.account.acceptPriv === false">
    <h4 class="text-light">Privacy Policy</h4>
    <app-privacy-policy></app-privacy-policy>
    <button class="btn btn-success btn-block" (click)="acceptPriv()">Accept Privacy Policy</button>
  </div>

  <div *ngIf="auth.authorizedUser.account.acceptPriv && auth.authorizedUser.account.acceptTos && auth.authorizedUser.account.acceptAup">
    <h3 class="text-light">Thank you. Please authenticate again to finish setting up your account.</h3>
    <a routerLink="/login" class="btn btn-success">Go to Authentication</a>
  </div>

</div>
