import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import {AuthService} from '../../services/auth.service';
import {RestService} from '../../services/rest.service';
import {AnnouncementsInterface} from '../../../interfaces/site/announcements.interface';
import {SnackbarService} from '../../services/snackbar.service';

@Component({
  selector: 'app-system-announcements',
  templateUrl: './system-announcements.component.html',
  styleUrls: ['./system-announcements.component.scss']
})
export class SystemAnnouncementsComponent implements OnInit {
  open: boolean = false;

  tinyMceSettings = {
    inline: false,
    statusbar: true,
    browser_spellcheck: true,
    height: 320,
    toolbar: 'formatselect | bold italic underline | bullist numlist | undo redo ',
    plugins: 'link',
  };

  dataModel: any = {
    title: '',
    body: '',
    dateEntered: moment().format(),
    postedBy: this.auth.authorizedUser.name,
    postToChannels: false,
  }

  announcements: AnnouncementsInterface[] = [];

  constructor(
    private auth: AuthService,
    private rest: RestService,
    private snackbar: SnackbarService
  ) {
    this.dataModel = {
      title: '',
      body: '',
      dateEntered: moment().format(),
      postedBy: this.auth.authorizedUser.name,
      postToChannels: false,
    };
  }

  ngOnInit(): void {
    //fetch announcements
    this.fetchAnnouncements();
  }

  fetchAnnouncements() {
    this.rest.get('announcements')
      .subscribe(
        (res) => {
          this.announcements = res;
        },
        (err) => {
          console.log(err);
        }
      )
  }

  reset($event: boolean) {
    this.open = false;
  }

  doSubmit() {
    console.log(this.dataModel);
    this.rest.post('announcements', this.dataModel)
      .subscribe(
        (res) => {
          this.fetchAnnouncements();
          this.snackbar.open("Posted Announcement Successfully")
        },
        (err) => {
          this.snackbar.open("An error occured while posting the announcement.");
          console.log(err);
        }
      )
  }

  formatTime(dateEntered: string) {
    return moment(dateEntered).fromNow();
  }
}
