<div class="hf-first-content-container container">
  <div class="row">
    <div class="col-md-6">
      <h2 class="text-white">{{chat.name}}</h2>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12 text-right" style="margin-top: 20px">
          <button (click)="openLink(chat.joinLink)" *ngIf="!chat.joinProhibit" class="btn btn-success">Join</button>
          <clr-signpost *ngIf="chat.joinProhibit">
            <clr-signpost-content *clrIfOpen>
              <h4>Request to Join</h4>
              <p>You must contact an admin to join {{chat.name}}. To view admins of this group, click Details.</p>
            </clr-signpost-content>
          </clr-signpost>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4">
      <h3 class="text-white">Staff</h3>
      <div *ngFor="let staff of chat.staff" class="text-white">
        @{{staff.member.handle}}<small *ngIf="staff.rank === 'creator'"> (creator)</small>
      </div>
    </div>
    <div class="col-md-8">
      <div [innerHTML]="chat.settings?.groupMessage"></div>
    </div>
  </div>

</div>
