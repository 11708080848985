import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-code-of-conduct',
  templateUrl: './code-of-conduct.component.html',
  styleUrls: ['./code-of-conduct.component.scss']
})
export class CodeOfConductComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
