import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import {MyRoutingModule} from './my-routing.module';
import {ClarityModule} from '@clr/angular';



@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    MyRoutingModule,
    ClarityModule
  ]
})
export class MyModule { }
