<div class="container hf-first-content-container">
  <h1 class="text-light">Your Settings</h1>
  <div *ngIf="processing">
  <div class="progress loop spinner-inverse">
    <progress></progress>
  </div>
  <p class="text-center text-light">We are fetching your account from the server, please wait...</p>
  </div>
  <div *ngIf="!processing" class="row">
  <div class="col-md-4">
    <button (click)="doLogout()" class="btn btn-danger">Logout</button>
  </div>
    <div class="col-md-8">
      <dl style="color: white !important">
        <dt>Username</dt>
        <dd>{{this.auth.authorizedUserAccount.username}}</dd>
        <dt>Telegram Username</dt>
        <dd *ngIf="auth.authorizedUserAccount.member != null">{{this.auth.authorizedUserAccount.member.id}}</dd>
        <dd *ngIf="auth.authorizedUserAccount.member == null">Not linked. Link your account by running /link in a conversation with @hypnofurs2bot.</dd>
        <dt>Account Status</dt>
        <dd *ngIf="auth.authorizedUserAccount.isRestricted">Restricted</dd>
        <dd *ngIf="auth.authorizedUserAccount.isRestricted === false">Good Standing</dd>
        <dt>Roles</dt>
        <dd *ngIf="auth.authorizedUserAccount.is_staff">Super Admin</dd>
        <dd *ngIf="auth.authorizedUserAccount.isAdmin">Admin</dd>
        <dd *ngIf="auth.authorizedUserAccount.isMod">Moderator</dd>
        <dd>Standard User</dd>
      </dl>
    </div>
  </div>
</div>
