import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RestService} from '../services/rest.service';
import {SnackbarService} from '../services/snackbar.service';
import {AuthService} from '../services/auth.service';
import {ChatsInterface} from '../../interfaces/telegram/chats.interface';

@Component({
  selector: 'app-chat-detail',
  templateUrl: './chat-detail.component.html',
  styleUrls: ['./chat-detail.component.scss']
})
export class ChatDetailComponent implements OnInit {

  chat: ChatsInterface;
  route: ActivatedRoute

  constructor(
    route: ActivatedRoute,
    private rest: RestService,
    private snackbar: SnackbarService,
    public auth: AuthService
  ) {
    this.route = route;
    this.route.params.subscribe(params => {
      this.reload(params['id']);
    })
  }

  reload(id: string) {
    this.rest.get('public/chats/' + id + '/')
      .subscribe(
        (res) => {
          console.log(res);
          this.chat = res;
        },
        (err) => {
          console.log(err);
        }
      )
  }

  ngOnInit(): void {
  }

  openLink(joinLink: string) {
    //do stuff
  }
}
