import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AdminDashboardComponent} from './admin-dashboard/admin-dashboard.component';
import {AdminGuard} from '../guards/admin/admin.guard';
import {AdminLayoutComponent} from './admin-layout/admin-layout.component';
import {AdminMembersComponent} from './admin-members/admin-members.component';
import {AdminMemberDetailComponent} from './admin-members/admin-member-detail/admin-member-detail.component';
import {AdminChatComponent} from './admin-chat/admin-chat.component';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminLayoutComponent,
    canActivate: [AdminGuard],
    children: [
      {
        path: 'dashboard',
        component: AdminDashboardComponent,
        canActivate: [AdminGuard]
      },
      {
        path: 'members',
        component: AdminMembersComponent,
        canActivate: [AdminGuard],
        children: [
          {
            path: 'detail/:id',
            component: AdminMemberDetailComponent,
            canActivate: [AdminGuard]
          }
        ]
      },
      {
        path: 'chat/:id',
        component: AdminChatComponent,
        canActivate: [AdminGuard]
      },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
