<div class="container hf-content-container hf-first-content-container hf-logo-container">
  <div class="hf-header-blur">
  <div class="text-center">
  <h1 class="text-light" style="margin-top: 0px; padding-top: 15px;">Welcome to HypnoFurs</h1>
  <h2 class="text-light">A telegram community of furries, hypnotists, and subjects.</h2>
    <p style="color: lightgray; padding-bottom: 15px;">Interested in Hypnosis? Part of the furry fandom? Want to get an idea of what hypnosis really is? Stop on by our rooms and find people just like you. We're a friendly, furry community always ready to lend a hand and teach people about the entertainment hypnosis can bring!<br>
      <br>
      Whether your new or old; dominate or submissive; furry, scaly, or just plain human, welcome to HypnoFurs.</p>
  </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-md-4">
      <a href="#" class="btn btn-block btn-success">View All Chats</a>
    </div>
    <div class="col-md-4">
      <a href="#" class="btn btn-block btn-success" style="background: deepskyblue; border: lightskyblue"><fa-icon [icon]="faTelegram"></fa-icon> Join the General Chat</a>
    </div>
    <div class="col-md-4">
      <a href="#" class="btn btn-block btn-success" style="background: purple; border: darkmagenta;"><fa-icon [icon]="faDiscord"></fa-icon> Discord</a>
    </div>
  </div>
</div>

<div class="container hf-content-container">
  <h1 class="text-light">Announcements</h1>
  <div class="bg-light" *ngFor="let announcement of this.announcements" style="border: 1px solid black; border-radius: 5px; padding: 10px; padding-bottom: 35px; margin-bottom: 25px;">
    <h2>{{announcement.title}} <small style="color: darkgray">by {{announcement.postedBy}} <span class="float-right" [innerHTML]="formatTime(announcement.dateEntered)"></span></small></h2>
    <div [innerHTML]="announcement.body"></div>
  </div>
</div>
