<h2 class="container text-white">HypnoFurs Chatrooms</h2>
<p class="container text-white">All chatrooms are on the <a href="https://telegram.org/" target="_blank">Telegram</a> platform.</p>
<div class="container hf-first-content-container text-light">
  <div class="row">
    <div class="col-md-4" *ngFor="let chat of chats">
      <div class="hf-content-container hf-group">
        <h5 class="text-center text-light">{{chat.name}}</h5>
        <div class="row" style="margin-top: 10px;">
          <div class="col-md-6">
            <a routerLink="/chats/{{chat.id}}" class="btn btn-block btn-primary btn-sm">Details</a>
          </div>
          <div class="col-md-6 text-center">
            <button (click)="openLink(chat.joinLink)" *ngIf="!chat.joinProhibit" class="btn btn-block btn-success btn-sm">Join</button>
            <clr-signpost *ngIf="chat.joinProhibit">
              <clr-signpost-content *clrIfOpen>
                <h4>Request to Join</h4>
                <p>You must contact an admin to join {{chat.name}}. To view admins of this group, click Details.</p>
              </clr-signpost-content>
            </clr-signpost>

          </div>
        </div>
        <p [innerHTML]="chat.settings?.groupMessage" style="padding-left: 15px; padding-right: 15px; padding-bottom: 25px"></p>
      </div>
    </div>
  </div>
</div>
