<main class="form-signin" *ngIf="authService.loggedIn === false">
  <form>
    <h1 class="h3 mb-3 fw-normal text-light">Please sign in</h1>
    <input type="email" id="inputEmail" class="form-control" name="email" [(ngModel)]="loginCredentials.email" placeholder="Username" required autofocus>
    <input type="password" id="inputPassword" name="password" [(ngModel)]="loginCredentials.password" class="form-control" placeholder="Password" required>
    <button class="w-100 btn btn-lg btn-primary" type="submit" (click)="doLogin()">Sign in</button>
    <hr>
    <div class="text-center">
      <app-telegram-login-widget></app-telegram-login-widget>
    </div>
  </form>
</main>
<div *ngIf="authService.loggedIn === true">
    <h1>Logged in as {{authService.authorizedUser.name}}</h1>
</div>
