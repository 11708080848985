import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {RestService} from '../../services/rest.service';

@Component({
  selector: 'app-system-dashboard',
  templateUrl: './system-dashboard.component.html',
  styleUrls: ['./system-dashboard.component.scss']
})
export class SystemDashboardComponent implements OnInit {
  users:       string = "enough";
  members:     number = 0;
  warns:       number = 0;
  bans:        number = 0;
  infractions: number = 0;
  chats:       number = 0;

  constructor(
    public auth: AuthService,
    public rest: RestService
  ) { }

  ngOnInit(): void {
    this.rest.get('members/count').subscribe((res)=>{this.members=res.count})
    this.rest.get('warns/count').subscribe((res)=>{this.warns=res.count})
    this.rest.get('bans/count').subscribe((res)=>{this.bans=res.count})
    this.rest.get('infractions/count').subscribe((res)=>{this.infractions=res.count})
    this.rest.get('chats/count').subscribe((res)=>{this.chats=res.count})
  }

}
