import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  constructor(
    public snackBar: MatSnackBar,
    public zone: NgZone,
  ) { }

  public open(message: string, action = 'Dismiss', duration = 4000): void {
    this.zone.run(() => {
      this.snackBar.open(message, action, {duration: duration, horizontalPosition: 'right', verticalPosition: 'top'});
    });
  }
}
