import { Component, OnInit } from '@angular/core';
import {AccountInterface} from '../../../interfaces/site/account.interface';
import {User} from '../../../interfaces/users.interface';
import {AuthService} from '../../services/auth.service';
import {RestService} from '../../services/rest.service';

@Component({
  selector: 'app-system-users',
  templateUrl: './system-users.component.html',
  styleUrls: ['./system-users.component.scss']
})
export class SystemUsersComponent implements OnInit {

  accounts: AccountInterface[];
  user: User[];

  constructor(
    public auth: AuthService,
    public rest: RestService
  ) { }

  ngOnInit(): void {
    this.accounts = new Array<AccountInterface>();
    this.rest.get('accounts/')
      .subscribe(
        (res) => {
                this.accounts = res;
              },
              (err2) => {
                console.log(err2);
              }
            )
  }

}
