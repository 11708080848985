import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RestService} from '../../../services/rest.service';
import {MembersInterface} from '../../../../interfaces/telegram/members.interface';
import {AuthService} from '../../../services/auth.service';
import {WarnsInterface} from '../../../../interfaces/mod/warns.interface';
import {BansInterface} from '../../../../interfaces/mod/bans.interface';

@Component({
  selector: 'app-admin-member-detail',
  templateUrl: './admin-member-detail.component.html',
  styleUrls: ['./admin-member-detail.component.scss']
})
export class AdminMemberDetailComponent implements OnInit {

  member: MembersInterface;

  navigation: string = 'overview';
  aWarns: WarnsInterface[];
  warns: WarnsInterface[];
  aBans: BansInterface[];
  bans: BansInterface[];

  constructor(
    route: ActivatedRoute,
    private rest: RestService,
    public auth: AuthService
  ) {
    route.params.subscribe(params => {
      this.reload(params['id']);
    })}

  ngOnInit(): void {
  }

  getActiveInfractions(){
    this.aWarns = [];
    this.aBans = [];
    this.rest.get('warns/?forgiven=false&member='+this.member.id)
      .subscribe(
        (res) => {
          this.aWarns = res;
        }
      )
    this.rest.get('bans/?forgiven=false&member='+this.member.id)
      .subscribe(
        (res) => {
          this.aBans = res;
        }
      )
  }

  reload(id: string){
    this.navigation = 'overview';
    this.aBans = [];
    this.aWarns = [];
    this.warns = [];
    this.bans = [];
    this.rest.get('members/'+id)
      .subscribe(
        (res) => {
          this.member = res;

          this.getActiveInfractions();

        },
        (err) => {

        }
      )

  }

  selectNavigation(section: string) {
    this.navigation = section;
    switch(section){
      case 'overview':
        this.getActiveInfractions();
        break;
      case 'record':
        this.getAllInfractions();
        break;
    }
  }

  getAllInfractions() {
      this.warns = [];
      this.bans = [];
      this.rest.get('warns/?member='+this.member.id)
        .subscribe(
          (res) => {
            this.warns = res;
          }
        )
      this.rest.get('bans/?member='+this.member.id)
        .subscribe(
          (res) => {
            this.bans = res;
          }
        )
  }
}
