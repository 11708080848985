import { Injectable } from '@angular/core';
import {RestService} from './rest.service';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class PingService {

  version: string = "Fetching...";

  constructor(
    private rest: RestService,
    private auth: AuthService
  ) { this.getVersion(); }

  getVersion() {
    console.log("Determining Version");
    this.rest.get('ping')
      .subscribe(
        (res) => {
          console.log(res);
          this.version = res.version;
        },
        (err) => {
          console.log(err);
          console.log("Hi");
          this.auth.navigate("/offline")
        }
      )
  }
}
