import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  processing = true;

  constructor(private auth: AuthService) { }

  ngOnInit(): void {
    this.auth.getNewAccount();
    this.processing = false;
  }

  doLogout() {
    try{
      this.auth.logout();
    }catch(e){ }
    this.auth.navigate('/login');
  }
}
