<div class="hf-first-content-container container" *ngIf="auth.authorizedUserAccount === null">
  <h1>Please wait while we check your credentials...</h1>
</div>
<div class="main-container" *ngIf="auth.isUserAuthentic">
  <div class="content-container" *ngIf="auth.authorizedUserAccount != null">
    <clr-vertical-nav [clrVerticalNavCollapsible]="true" [(clrVerticalNavCollapsed)]="collapsed" style="background: #222;">
      <a clrVerticalNavLink routerLink="/admin/dashboard" routerLinkActive="active">
        <cds-icon clrVerticalNavIcon shape="dashboard"></cds-icon>
        Dashboard
      </a>
      <a clrVerticalNavLink routerLink="/admin/members/" routerLinkActive="active">
        <cds-icon clrVerticalNavIcon shape="user"></cds-icon>
        Member Lookup
      </a>
      <a clrVerticalNavLink routerLink="/admin/warns/" routerLinkActive="active">
        <cds-icon clrVerticalNavIcon shape="exclamation-triangle"></cds-icon>
        Warns Table
      </a>
      <a clrVerticalNavLink routerLink="/admin/bans/" routerLinkActive="active">
        <cds-icon clrVerticalNavIcon shape="heart-broken"></cds-icon>
        Bans Table
      </a>
      <a clrVerticalNavLink routerLink="/admin/bot/" routerLinkActive="active">
        <cds-icon clrVerticalNavIcon shape="script-execute"></cds-icon>
        Bot Control
      </a>
      <a clrVerticalNavLink routerLink="/admin/statistics/" routerLinkActive="active">
        <cds-icon clrVerticalNavIcon shape="bell-curve"></cds-icon>
        Admin Statistics
      </a>
      <a clrVerticalNavLink routerLink="/admin/reports/" routerLinkActive="active">
        <cds-icon clrVerticalNavIcon shape="announcement"></cds-icon>
        User Reports
      </a>
      <a clrVerticalNavLink routerLink="/admin/tickets/" routerLinkActive="active">
        <cds-icon clrVerticalNavIcon shape="eye"></cds-icon>
        Trouble Tickets
      </a>
      <hr style="background: white; color: white;">
      <a *ngFor="let chat of chats" clrVerticalNavLink routerLink="/admin/chat/{{chat.id}}" routerLinkActive="active" style="height: auto;">
        <cds-icon clrVerticalNavIcon shape="users"></cds-icon>
        {{chat.name}}
      </a>
    </clr-vertical-nav>
    <div class="content-area" style="background: #333; color: #DDD;">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
