import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClarityModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { LoginComponent } from './login/login.component';
import { FrontComponent } from './front/front.component';
import {MyModule} from './my/my.module';
import {TelegramLoginWidgetComponent} from './login/telegram-login-widget.component';
import {AdminModule} from './admin/admin.module';
import {FormsModule} from '@angular/forms';
import {SystemModule} from './system/system.module';
import {MatSnackBarConfig, MatSnackBarModule, MatSnackBarRef} from '@angular/material/snack-bar';
import { AgreementsComponent } from './agreements/agreements.component';
import { PrivacyPolicyComponent } from './agreements/documents/privacy-policy/privacy-policy.component';
import { TermsComponent } from './agreements/documents/terms/terms.component';
import { AcceptableUploadComponent } from './agreements/documents/acceptable-upload/acceptable-upload.component';
import {AccordionService} from '@clr/angular/accordion/providers/accordion.service';
import { DocHolderComponent } from './agreements/documents/doc-holder/doc-holder.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CreditsComponent } from './agreements/documents/credits/credits.component';
import { OfflineComponent } from './offline/offline.component';
import {MarkdownModule} from 'ngx-markdown';
import { ChatListComponent } from './chat-list/chat-list.component';
import { ChatDetailComponent } from './chat-detail/chat-detail.component';
import { CodeOfConductComponent } from './agreements/documents/code-of-conduct/code-of-conduct.component';

export function tokenGetter() {
  return localStorage.getItem("access_token");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FrontComponent,
    TelegramLoginWidgetComponent,
    AgreementsComponent,
    PrivacyPolicyComponent,
    TermsComponent,
    AcceptableUploadComponent,
    DocHolderComponent,
    CreditsComponent,
    OfflineComponent,
    ChatListComponent,
    ChatDetailComponent,
    CodeOfConductComponent
  ],
  imports: [
    BrowserModule,
    MatSnackBarModule,
    AppRoutingModule,
    ClarityModule,
    BrowserAnimationsModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: [],
        disallowedRoutes: [],
      },
    }),
    MyModule,
    AdminModule,
    FormsModule,
    SystemModule,
    FontAwesomeModule,
    MarkdownModule.forRoot(),
  ],
  exports: [],
  providers: [
    {
      provide: MatSnackBarConfig,
      useValue: {}
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
