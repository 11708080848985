import { Component } from '@angular/core';
import {AuthService} from './services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SnackbarService} from './services/snackbar.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { NgZone } from '@angular/core';
import {PingService} from './services/ping.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'hfui';
  collapsed = false;
  constructor(
    public authService: AuthService,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public snackbar: SnackbarService,
    public ping: PingService
  ) {
  }
}
