<div class="clr-row">
  <div class="clr-col-2">
    <clr-input-container>
      <label class="text-light">Search for a User</label>
      <input clrInput name="inp" (keyup)="fetchMembers($event)" class="text-light" type="text" placeholder="Enter a user.">
    </clr-input-container>
    <table class="table">
      <tbody>
        <tr *ngFor="let member of members">
          <td style="cursor: pointer" (click)="loadMember(member)">@{{member.handle}} {{member.id}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="clr-col-10">
    <router-outlet></router-outlet>
  </div>
</div>


