<div class="hf-first-content-container container">
  <h1>Hello, {{auth.authorizedUser.name}}</h1>
  <div class="row">
    <div class="col-md-3 text-center"><div class="hf-content-container"><h2>{{users}}</h2><h3>Users</h3></div></div>
    <div class="col-md-3 text-center"><div class="hf-content-container"><h2>{{members}}</h2><h3>Members</h3></div></div>
    <div class="col-md-3 text-center"><div class="hf-content-container"><h2>{{warns}}</h2><h3>Warns</h3></div></div>
    <div class="col-md-3 text-center"><div class="hf-content-container"><h2>{{bans}}</h2><h3>Bans</h3></div></div>
    <div class="col-md-3 text-center"><div class="hf-content-container"><h2>{{infractions}}</h2><h3>Infractions</h3></div></div>
    <div class="col-md-3 text-center"><div class="hf-content-container"><h2>{{chats}}</h2><h3>Chats</h3></div></div>
    <div class="col-md-3 text-center"><div class="hf-content-container"><h2>0</h2><h3>Files</h3></div></div>
    <div class="col-md-3 text-center"><div class="hf-content-container"><h2>1</h2><h3>Poggers</h3></div></div>
  </div>
</div>
