<div class="hf-content-container container" *ngIf="auth.isUserAuthentic">
<h1 class="text-light">Hello, {{auth.authorizedUserAccount.username}}</h1>

  <h4 class="text-light">Recent Warnings</h4>
  <clr-datagrid [clrDgLoading]="loadingWarns">
    <clr-dg-column [clrDgField]="'givenToId'">Member
    <clr-dg-string-filter [clrDgStringFilter]="memberFilter"></clr-dg-string-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'staffId'">Staff
      <clr-dg-string-filter [clrDgStringFilter]="staffFilter"></clr-dg-string-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'dateIssued'">Date Issued
      <clr-dg-string-filter [clrDgStringFilter]="dateIssuedFilter"></clr-dg-string-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'chat'">Chat
      <clr-dg-string-filter [clrDgStringFilter]="chatFilter"></clr-dg-string-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'infractionType'">Infraction Type
      <clr-dg-string-filter [clrDgStringFilter]="infractionTypeFilter"></clr-dg-string-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'message'">Message
      <clr-dg-string-filter [clrDgStringFilter]="messageFilter"></clr-dg-string-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'dateExpires'">Expires
      <clr-dg-string-filter [clrDgStringFilter]="expiresFilter"></clr-dg-string-filter>
    </clr-dg-column>

    <!--

    var / let

    var x1 = "test";
    let x1 = "test";

    var x1;

    let x1: any = "test";

    -->

    <clr-dg-row *ngFor="let warn of warns">
      <clr-dg-cell><a routerLink="/admin/members/detail/{{warn.member.id}}">@{{warn.member?.handle}}</a></clr-dg-cell>
      <clr-dg-cell>@{{warn.staff?.handle}}</clr-dg-cell>
      <clr-dg-cell>{{warn.dateIssued}}</clr-dg-cell>
      <clr-dg-cell><a routerLink="/admin/chat/{{warn.chat.id}}">{{warn.chat?.name}}</a></clr-dg-cell>
      <clr-dg-cell>{{warn.infraction?.longName}}</clr-dg-cell>
      <clr-dg-cell>{{warn.message}}</clr-dg-cell>
      <clr-dg-cell>{{warn.dateExpires}}</clr-dg-cell>
    </clr-dg-row>

    <clr-dg-placeholder>No warns found. I know, I can't believe it either.</clr-dg-placeholder>

    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="10">
        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Warns per page</clr-dg-page-size>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} warns
      </clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>

  <h4 class="text-light">Recent Bans</h4>
  <clr-datagrid [clrDgLoading]="loadingBans">
    <clr-dg-column [clrDgField]="'memberId'">Member</clr-dg-column>
    <clr-dg-column [clrDgField]="'staffId'">Staff</clr-dg-column>
    <clr-dg-column [clrDgField]="'dateIssued'">Date Issued</clr-dg-column>
    <clr-dg-column [clrDgField]="'chatId'">Chat</clr-dg-column>
    <clr-dg-column [clrDgField]="'message'">Message</clr-dg-column>
    <clr-dg-column [clrDgField]="'dateExpires'">Expires</clr-dg-column>

    <clr-dg-row *ngFor="let ban of bans">
      <clr-dg-cell><a routerLink="/admin/members/detail/{{ban.member.id}}">@{{ban.member.id?.handle}}</a></clr-dg-cell>
      <clr-dg-cell>{{ban.staff?.handle}}</clr-dg-cell>
      <clr-dg-cell>{{ban.dateIssued}}</clr-dg-cell>
      <clr-dg-cell><a routerLink="/admin/chat/{{ban.chat.id}}">{{ban.chat?.name}}</a></clr-dg-cell>
      <clr-dg-cell>{{ban.message}}</clr-dg-cell>
      <clr-dg-cell>{{ban.dateExpires}}</clr-dg-cell>
    </clr-dg-row>

    <clr-dg-placeholder>No bans found. I know, I can't believe it either.</clr-dg-placeholder>

    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgTotalItems]="numd"></clr-dg-pagination>
      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{nume}} bans
    </clr-dg-footer>
  </clr-datagrid>
  <br>
</div>
