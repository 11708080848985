<table class="table table-striped">
  <thead>
    <tr>
      <th>Username</th>
      <th>Active?</th>
      <th>Account</th>
    </tr>
    <tr *ngFor="let usr of accounts">
      <td>{{usr.username}}</td>
      <td>{{usr.is_active}}</td>
      <td *ngIf="!usr.isRestricted"><button class="btn btn-sm btn-primary" (click)="auth.navigate('/system/users/account/'+usr.id)">Open Account</button></td>
      <td *ngIf="usr.isRestricted">System Account</td>
    </tr>
  </thead>
</table>
