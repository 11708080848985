import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {AccountInterface} from '../../../interfaces/site/account.interface';
import {ClrDatagridStateInterface, ClrDatagridStringFilterInterface} from '@clr/angular';
import {RestService} from '../../services/rest.service';
import {WarnsInterface} from '../../../interfaces/mod/warns.interface';
import {BansInterface} from '../../../interfaces/mod/bans.interface';


@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {
  loadingWarns = true;
  warns: WarnsInterface[];
  bans: BansInterface[];
  numd = 0;
  nume = 0;
  loadingBans = true;
  memberFilter: ClrDatagridStringFilterInterface<any>;
  staffFilter: ClrDatagridStringFilterInterface<any>;
  dateIssuedFilter: ClrDatagridStringFilterInterface<any>;
  chatFilter: ClrDatagridStringFilterInterface<any>;
  infractionTypeFilter: ClrDatagridStringFilterInterface<any>;
  messageFilter: ClrDatagridStringFilterInterface<any>;
  expiresFilter: ClrDatagridStringFilterInterface<any>;

  constructor(public auth: AuthService, public rest: RestService) {
    this.warns = new Array<WarnsInterface>();
    this.bans = new Array<BansInterface>();
    this.refreshWarns();
    this.refreshBans();
  }

  refreshWarns() {
    this.loadingWarns = true;
    console.log("loading warns")
    this.rest.get('warns/')
      .subscribe(
        (res) => {
          this.warns = res;
          this.numd = this.warns.length;
          this.loadingWarns = false;
        },
        (err) => {
          console.log(err);
        }
      )
  }

  refreshBans() {
    this.loadingBans = true;
    console.log("loading bans")
    this.rest.get('bans/')
      .subscribe(
        (res) => {
          this.bans = res;
          this.nume = this.bans.length;
          this.loadingBans = false;
        },
        (err) => {
          console.log(err);
        }
      )
  }


  ngOnInit(): void {
  }

}
