import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from './login/login.component';
import {FrontComponent} from './front/front.component';
import {AgreementsComponent} from './agreements/agreements.component';
import {AuthGuard} from './guards/auth/auth.guard';
import {AuthAgreementGuard} from './guards/auth-agreement/auth-agreement.guard';
import {AcceptableUploadComponent} from './agreements/documents/acceptable-upload/acceptable-upload.component';
import {PrivacyPolicyComponent} from './agreements/documents/privacy-policy/privacy-policy.component';
import {TermsComponent} from './agreements/documents/terms/terms.component';
import {AnonAuthAgreementsGuard} from './guards/anon-auth-agreements/anon-auth-agreements.guard';
import {DocHolderComponent} from './agreements/documents/doc-holder/doc-holder.component';
import {CreditsComponent} from './agreements/documents/credits/credits.component';
import {OfflineComponent} from './offline/offline.component';
import {ChatListComponent} from './chat-list/chat-list.component';
import {ChatDetailComponent} from './chat-detail/chat-detail.component';
import {CodeOfConductComponent} from './agreements/documents/code-of-conduct/code-of-conduct.component';

const routes: Routes = [
  {
    path: '',
    component: FrontComponent,
    canActivate: [AnonAuthAgreementsGuard]
  },
  {
    path: 'offline',
    component: OfflineComponent
  },
  {
    path: 'chats',
    component: ChatListComponent
  },
  {
    path: 'chats/:id',
    component: ChatDetailComponent
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AnonAuthAgreementsGuard]
  },
  {
    path: 'agreements',
    component: AgreementsComponent,
    canActivate: [AuthAgreementGuard]
  },
  {
    path: 'docs',
    component: DocHolderComponent,
    children: [
      {
        path: 'aup',
        component: AcceptableUploadComponent,
        canActivate: [AnonAuthAgreementsGuard]
      },
      {
        path: 'privacy',
        component: PrivacyPolicyComponent,
        canActivate: [AnonAuthAgreementsGuard]
      },
      {
        path: 'tos',
        component: TermsComponent,
        canActivate: [AnonAuthAgreementsGuard]
      },
      {
        path: 'coc',
        component: CodeOfConductComponent,
        canActivate: [AnonAuthAgreementsGuard]
      }

    ]
  },
  {
    path: 'aup',
    redirectTo: 'docs/aup',
    pathMatch: 'full'
  },
  {
    path: 'privacy',
    redirectTo: 'docs/privacy',
    pathMatch: 'full'
  },
  {
    path: 'tos',
    redirectTo: 'docs/tos',
    pathMatch: 'full'
  },
  {
    path: 'coc',
    redirectTo: 'docs/coc',
    pathMatch: 'full'
  },
  {
    path: 'credits',
    component: CreditsComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
