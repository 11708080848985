import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TelegramLoginService {
  constructor(private auth: AuthService) {
    window['loginViaTelegram'] = loginData => this.loginViaTelegram(loginData);
    console.log("TelegramLoginService", "Ready")
  }

  private loginViaTelegram(loginData: any) {
    // If the login should trigger view changes, run it within the NgZone.
    console.log(loginData);


    this.auth.telegramLogin(loginData);
  }
}
